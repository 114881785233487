.dvb-rechnung-heading-attribute {
    display: flex;
    justify-content: space-around;

    & i {
        padding: 0 4px 0 0;
        text-align: center;
        display: inline-block;
    }
}
