@use 'styles/variables';

.nav-tabs {
    > li {
        > a {
            border-radius: variables.$navbar-border-radius;
            color: variables.$color-main-normal;
            font-weight: normal;

            &:hover {
                background-color: variables.$color-guide-bright;
            }
        }

        &.active {
            > a,
            > a:hover,
            > a:focus {
                color: variables.$color-main-normal;
            }
        }
    }
}
