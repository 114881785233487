@use 'styles/mixins';
@use 'styles/variables';

.kinderliste {
    .selected-eintrag {
        background-color: variables.$color-main-bright;

        .tage {
            display: none;
        }

        .hover-only {
            display: flex;
            align-items: center;
        }
    }

    .eintrag {
        border-bottom: variables.$border-default;
        padding: 0 variables.$small-abstand;
        display: flex;
        align-items: center;
        height: variables.$accordion-height;
        cursor: pointer;

        > div {
            width: 0;
        }

        .tage {
            @include mixins.flex($grow: variables.$tage-flex-grow);

            display: flex;

            .tag {
                @include mixins.flex($grow: 1);
                @include mixins.colors-default;

                border-radius: variables.$input-border-radius;
                width: 0;
                height: variables.$small-tageseinheit-cell-height;
                line-height: variables.$small-tageseinheit-cell-height;
                text-align: center;
                margin-right: variables.$tageseinheit-cell-margin;
                font-weight: variables.$bold-font-weight;
                font-size: variables.$font-size-small;

                &:last-child {
                    margin-right: 0;
                }
            }

            .selected-tag {
                background-color: variables.$color-main-normal;
                color: variables.$color-main-bright-on-hover;
            }

            .filtered-tag {
                background-color: variables.$color-guide-normal;
                color: variables.$color-guide-bright-on-hover;
            }
        }

        .hover-only {
            @include mixins.flex($grow: variables.$tage-flex-grow);

            display: none;

            .geburtstag {
                margin-right: auto;
            }

            .prio {
                border-radius: variables.$input-border-radius;
                font-weight: variables.$bold-font-weight;
                font-size: variables.$font-size-small;
                background-color: variables.$color-main-normal;
                color: variables.$color-main-bright-on-hover;
                padding: 0 variables.$small-abstand;
                line-height: variables.$small-tageseinheit-cell-height;
                text-align: center;
            }
        }

        &:hover {
            @include mixins.colors-default;

            .tage {
                display: none;
            }

            .hover-only {
                display: flex;
                align-items: center;
            }
        }

        .status-icon {
            width: variables.$list-icon-width;
        }

        .status-und-name {
            @include mixins.flex($grow: variables.$status-und-name-flex-grow);

            text-align: left;
            display: block;
            width: 0;
        }

        .icons {
            @include mixins.flex($grow: variables.$icons-flex-grow);
        }

        .pensum {
            @include mixins.flex($grow: variables.$pensum-flex-grow);
        }

        .plaetze {
            @include mixins.flex($grow: variables.$plaetze-flex-grow);

            ul {
                display: flex;
            }
        }

        .kindergarten {
            @include mixins.flex($grow: variables.$kindergarten-flex-grow);
        }
    }

    .detail {
        background-color: variables.$color-main-bright;
        padding: variables.$normal-abstand variables.$small-abstand;
        border-bottom: variables.$border-default;

        .bewerbung {
            margin-bottom: variables.$normal-abstand;
        }

        .feld {
            &:not(.selected),
            &.border-green {
                background-color: variables.$background-color !important;
                color: variables.$color-main-normal !important;
            }
        }

        .no-feld {
            background: linear-gradient(
                to top right,
                rgb(0 0 0 / 0%) 0,
                rgb(0 0 0 / 0%) 47%,
                variables.$color-main-bright 50%,
                rgb(0 0 0 / 0%) 53%,
                rgb(0 0 0 / 0%) 100%
            ) !important;
        }

        .kind-actions {
            @include mixins.make-wider;
            @include mixins.border-top;

            margin-top: variables.$normal-abstand;
            border-bottom: none;

            ul {
                padding-top: variables.$normal-abstand;
                padding-left: variables.$small-abstand;
            }
        }

        .collapsible-panel-container {
            border: none;
            padding: 0;
        }
    }
}
