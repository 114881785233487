@use 'styles/mixins';
@use 'styles/variables';

.dvb-kontaktperson-offene-posten-liste {
    .offener-posten > .collapsible-panel-container {
        padding: 0;
        min-height: variables.$big-abstand;
        line-height: variables.$big-abstand;

        p {
            line-height: variables.$line-height-small;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .posten-selectable {
        cursor: pointer;
    }

    .posten-selected,
    .posten-selectable:hover {
        @include mixins.colors-default;
    }

    .offener-posten-datum {
        position: absolute;
        margin-top: -#{variables.$font-size-base};
    }
}
