@use 'styles/variables';

.dvb-firma-profil {
    .h2 {
        margin: -#{variables.$small-abstand} 0 0;

        label {
            font-size: variables.$font-size-h2 !important;

            // overwrite right alignment on smaller screens
            text-align: left;
        }

        input {
            font-size: variables.$font-size-h2 !important;
        }
    }
}
