@use 'styles/mixins';
@use 'styles/variables';

.dvb-kita-faktura-rechnungslauf {
    .badge {
        @include mixins.colors-selected;

        &.inactive {
            background-color: variables.$color-main-middle;
        }
    }
}
