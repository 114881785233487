@use 'styles/modules/bs-var';
@use 'styles/variables';

.dvb-existing-extra-plaetze {
    .collapsible-panel-container {
        padding: variables.$small-abstand 0;

        .folding-icon {
            line-height: bs-var.$input-height-base;
        }
    }

    .platz-details {
        display: flex;

        .wochenplan-flex,
        .details-flex,
        .buttons-flex {
            flex: 1 1 auto;
            flex-basis: 0;
        }

        .details-flex {
            padding: 0 variables.$small-abstand;

            .platz-type {
                margin-bottom: 0;
            }
        }

        .buttons-flex {
            display: flex;
            flex-direction: column;

            .actions {
                margin-top: auto;
            }
        }
    }
}
