@use 'styles/modules/bs-var';
@use 'styles/variables';

.dvb-rechnung-position {
    .position-kind-container {
        position: relative;
        height: (4 * variables.$small-abstand) + variables.$font-size-base;
    }

    .position-kind {
        font-size: bs-var.$font-size-h4;
        border-top: variables.$border-white;
        margin: variables.$small-abstand -#{variables.$small-abstand};
        position: absolute;
        overflow: hidden;
        width: calc(100% + #{variables.$small-abstand * 2});
        padding: variables.$small-abstand;
    }

    .position-ueberschrift {
        font-size: variables.$font-size-h3;
        width: 30%;
        float: left;
        line-height: variables.$line-height-normal;
    }

    .position-betrag {
        margin: 0;
        float: right;
        width: 65%;
        line-height: variables.$line-height-normal;

        & div:last-child {
            padding-right: 0;
        }
    }

    .position-spacer {
        padding-top: variables.$small-abstand;
    }

    .position-divider {
        clear: both;
    }
}
