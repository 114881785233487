@use 'styles/variables';

.dvb-adresse {
    .click-to-edit-adresse {
        .dvb-adresse-display:hover {
            border-color: variables.$input-border;
            background-color: variables.$color-main-bright;
            margin-left: 0;
            padding-left: variables.$small-abstand;
        }

        .form-row.dvb-adresse-form {
            margin-bottom: 0;
        }
    }
}
