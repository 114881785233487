@use 'modules/fa';
@use 'styles/variables' as v;

.dvb-calendar {
    margin-top: v.$normal-abstand !important;

    $grid-line-width: 2px;

    * {
        outline: 0 none;
    }

    table {
        table-layout: fixed;
        width: 7 * v.$btn-small-width + 8 * $grid-line-width;
    }

    th {
        .btn {
            background-color: v.$dropdown-inverse-bg;

            // Hack to replace Glyphicons with FontAwesome Icons
            .glyphicon {
                @include fa.fa-icon;

                background-color: transparent;
            }

            .glyphicon-chevron-left::before {
                content: fa.$fa-var-chevron-left;
            }

            .glyphicon-chevron-right::before {
                content: fa.$fa-var-chevron-right;
            }

            .glyphicon-chevron-up::before {
                content: fa.$fa-var-chevron-up;
            }

            .glyphicon-chevron-down::before {
                content: fa.$fa-var-chevron-down;
            }
        }

        color: v.$dropdown-link-color;
    }

    th,
    td {
        border: $grid-line-width solid transparent;

        .btn,
        .btn i {
            background-color: v.$dropdown-button-bg;
            color: v.$dropdown-button-color;
        }

        .btn {
            font-size: v.$btn-small-font-size;
            line-height: v.$btn-small-height;
            padding: 0;

            &.active,
            &:hover,
            &:focus {
                background-color: v.$dropdown-button-bg-hover;
                box-shadow: none;
                color: v.$dropdown-button-color-hover;
            }

            &.pull-left,
            &.pull-right {
                background-color: transparent;
                font-size: v.$btn-small-height;

                &:hover,
                &:active,
                &:focus {
                    color: v.$dropdown-link-hover-color;
                }
            }

            .text-info {
                color: v.$color-main-normal; // TODO Farbe definieren
            }

            // no inactive elemets found -> no inactive styling yet
        }
    }

    tr:first-child th {
        padding-bottom: v.$small-abstand;
    }

    small {
        font-size: v.$btn-small-font-size;
    }

    // removes the button bar to switch to the next month/year
    &.no-switching {
        thead {
            tr:first-child {
                display: none;
            }
        }
    }

    .bs-datepicker-container {
        padding: v.$normal-abstand;
        background-color: v.$color-guide-bright;
        text-align: center;
        font-size: v.$font-size-small;

        &::before {
            border-color: v.$dropdown-bg transparent;
            border-style: none solid solid;
            border-width: v.$small-abstand;
            content: '';
            display: block;
            height: 0;
            left: v.$normal-abstand;
            position: absolute;
            top: v.$small-abstand;
            width: 0;
        }

        .bs-datepicker-head {
            bs-datepicker-navigation-view {
                display: block;
                width: 100%;
                overflow: hidden;
                margin-bottom: v.$small-abstand;

                .previous {
                    float: left;

                    &::before {
                        content: fa.$fa-var-chevron-left;
                    }
                }

                .next {
                    float: right;

                    &::before {
                        content: fa.$fa-var-chevron-right;
                    }
                }

                .previous,
                .next,
                .current {
                    background: transparent;
                    border: none;
                }

                .previous,
                .next {
                    @include fa.fa-icon;

                    color: transparent;
                    font-size: v.$font-size-large;

                    &::before {
                        color: v.$color-guide-hover;
                    }
                }

                .current {
                    padding: 0 v.$normal-abstand;
                    border-radius: 2px;
                    background-color: v.$dropdown-button-bg;
                    color: v.$dropdown-button-color;
                    font-weight: v.$bold-font-weight;
                    line-height: 22px;

                    &:hover,
                    &:focus {
                        background-color: v.$dropdown-button-bg-hover;
                        color: v.$dropdown-button-color-hover;
                    }
                }
            }
        }

        .bs-datepicker-body {
            table {
                th {
                    width: v.$btn-small-width + $grid-line-width;
                    height: 18px;
                    padding-bottom: 0;
                    color: v.$color-guide-hover;
                    border: $grid-line-width solid transparent;
                    text-align: center;
                }

                td {
                    width: v.$btn-small-width + $grid-line-width;
                    height: v.$btn-small-height + $grid-line-width;
                    border: $grid-line-width solid transparent;

                    span {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        line-height: v.$btn-small-height;
                        border-radius: 2px;
                        background-color: v.$dropdown-button-bg;
                        color: v.$color-guide-hover;
                        font-weight: v.$bold-font-weight;
                        cursor: pointer;
                        border: 1px solid transparent;

                        &:hover,
                        &:focus {
                            background-color: v.$dropdown-button-bg-hover;
                            color: v.$dropdown-button-color-hover;
                        }

                        &.is-other-month {
                            color: #777;
                        }

                        &.today {
                            background-color: v.$dropdown-button-bg-hover;
                            color: v.$dropdown-button-color-hover;
                        }

                        &.selected {
                            background-color: v.$dropdown-button-bg-hover;
                            color: v.$color-main-normal;
                            border: 1px solid v.$color-main-normal;
                        }
                    }

                    span.disabled,
                    &.disabled span {
                        opacity: 0.65;
                        background-color: v.$color-main-bright;
                        color: v.$color-main-middle;
                        cursor: not-allowed;
                    }
                }
            }
        }

        .bs-datepicker-buttons {
            text-align: left;
            padding: v.$small-abstand $grid-line-width 0 $grid-line-width;

            button {
                padding: 5px 10px;
                font-size: v.$font-size-small;
                line-height: v.$line-height-small;
            }
        }
    }
}

bs-datepicker-container {
    z-index: 1060;
}
