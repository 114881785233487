@use 'styles/modules/bs-var';
@use 'styles/variables';

.dvb-kibon-exchange-entity-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (min-width: bs-var.$screen-sm-min) {
        .dvb-search-entity {
            min-width: 400px;
        }
    }
}
