@use 'styles/variables';

.has-datepicker {
    .dvb-datepicker-text-field {
        margin-bottom: variables.$normal-abstand;
        width: 100%;

        .popup-btn {
            z-index: 1;
        }
    }

    .datepicker:focus {
        // override default uib-bootstrap border glow
        outline: 0 none;
    }

    .dvb-button-bar {
        padding: 10px 9px 2px 2px;
    }

    .dropdown-menu.dvb-calendar {
        $grid-line-width: 2px;

        margin-top: variables.$normal-abstand;
        padding: variables.$normal-abstand - $grid-line-width;

        &::before {
            border-color: variables.$dropdown-bg transparent;
            border-style: none solid solid;
            border-width: variables.$small-abstand;
            content: '';
            display: block;
            height: 0;
            left: variables.$normal-abstand;
            position: absolute;
            top: -#{variables.$small-abstand};
            width: 0;
        }

        .dvb-button-bar button {
            background-color: variables.$btn-primary-bg;
            color: variables.$btn-primary-color;
        }
    }
}

.dvb-click2edit-open {
    .dvb-datepicker-text-field {
        margin-bottom: 0;
    }
}
