@use 'styles/variables';

.pagination {
    li {
        text-align: center;

        & > a {
            width: 45px;
        }

        &.pagination-first,
        &.pagination-prev,
        &.pagination-next,
        &.pagination-last {
            & > a {
                // weil die Texte FontAwesome Icons sind
                font-family: FontAwesome, sans-serif;
            }
        }

        &.active > a {
            &:hover,
            &:focus {
                background-color: variables.$color-guide-hover;
            }
        }
    }
}
