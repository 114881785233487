@use 'styles/variables';

.bank-statement-entry-assign {
    &.modal {
        display: block;
    }

    .assignment-icon {
        position: absolute;
        width: 100%; // to allow the tooltip to have the correct size
    }
}
