@use 'styles/mixins';
@use 'styles/variables';

.dvb-wizard {
    .wizard-header {
        display: flex;

        .step {
            flex-basis: 0;
            flex-grow: 1;
            text-align: center;

            .icon,
            .line {
                display: inline-block;
                float: left;
            }

            .icon {
                @include mixins.timeline-icon;

                background-color: variables.$color-main-middle;
                cursor: pointer;

                &.complete {
                    background-color: variables.$color-guide-middle;
                }

                &.error.active {
                    background-color: variables.$color-contrast-normal;
                }

                &.active {
                    background-color: variables.$color-guide-normal;
                }

                &.error {
                    background-color: variables.$color-contrast-middle;
                }
            }

            .line {
                height: 1px;
                width: calc(50% - #{variables.$timeline-width * 0.5 + variables.$smaller-abstand});
                margin-top: variables.$timeline-width * 0.5;
                background-color: variables.$color-guide-middle;

                &.line-left {
                    margin-right: variables.$smaller-abstand;
                }

                &.line-right {
                    margin-left: variables.$smaller-abstand;
                }

                &.transparent {
                    background-color: transparent;
                }
            }

            .title {
                clear: both;
                padding-top: variables.$smaller-abstand;
            }
        }
    }
}
