@use 'styles/variables';

.dvb-loading-button {
    .spinner,
    .spinner-right {
        display: inline-block;
    }

    .spinner {
        position: absolute;
        margin-left: -3 * variables.$small-abstand;
        color: variables.$color-guide-normal;
    }

    .spinner-right {
        margin-left: 2 * variables.$small-abstand;
    }

    .btn-link {
        .spinner {
            margin-left: -1.5 * variables.$small-abstand;
        }

        .spinner-right {
            margin-left: variables.$smaller-abstand;
        }
    }

    .not-loading {
        animation: none 0s;
    }
}
