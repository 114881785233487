@use 'styles/variables';

.dvb-kind-monat-zuweisen {
    .filter-bar.access-control-visible {
        padding-bottom: 0;
        border-bottom: 0;
    }

    .access-control {
        overflow: hidden;
        padding: 0 variables.$padding-base-horizontal;
        line-height: variables.$tall-line-height;
        background-color: variables.$color-guide-bright;
        border-top: variables.$border-default;
    }
}
