@use 'styles/variables';

.dvb-kibon-betreuung-detail {
    th {
        text-align: right;

        &.multi-line {
            white-space: normal;
            line-height: variables.$line-height-normal;
        }
    }

    th[scope="row"] {
        font-weight: variables.$normal-font-weight;
    }
}
