/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */
// Bootstrap variable overrides
@import 'styles/variables';

// External dependencies
@import 'styles/bootstrap';
@import 'font-awesome';
@import 'styles/custom-icons/custom-icons';
@import 'angular/angular-csp.css';
@import 'angular-ui-bootstrap/src/datepicker/datepicker.css';
@import 'angular-ui-bootstrap/src/position/position.css';
@import 'angular-ui-bootstrap/src/datepickerPopup/popup.css';
@import 'angular-ui-bootstrap/src/tooltip/tooltip.css';
@import 'angular-ui-bootstrap/src/typeahead/typeahead.css';
@import 'angular-ui-bootstrap/dist/ui-bootstrap-csp.css';
@import 'angular-ui-tree/dist/angular-ui-tree.min.css';
@import '@angular/cdk/overlay-prebuilt.css';

// Our own style modules
@import 'styles/mixins';
@import 'modules/whitescreen';
@import 'modules/misc';

// Bootstrap setup
@import 'styles/responsive';
@import 'styles/scaffolding';

// Bootstrap customisation
@import 'styles/accordion';
@import 'styles/buttons';
@import 'styles/calendar';
@import 'styles/filter';
@import 'styles/forms';
@import 'styles/header';
@import 'styles/history';
@import 'styles/layout';
@import 'styles/misc';
@import 'styles/modal';
@import 'styles/pagination';
@import 'styles/panel-list';
@import 'styles/panels';
@import 'styles/popovers';
@import 'styles/print';
@import 'styles/spinner';
@import 'styles/tabs';

// UI-Tree Customisation
@import 'styles/tree';

// UI-Scroll timeline
@import 'styles/timeline';

// tinymce
@import 'styles/tinymce';

// ngx-colors
@import 'styles/color-picker';

// AngularJS components
@import 'angularjs-ui';
@import 'app/angularjs';

// root CSS variables, accessible via StyleVariableService
@import 'styles/modules/js';

// misc global personal styling
@import 'styles/personal-misc';
