@use 'sass:math';
@use 'styles/variables';
@use 'styles/modules/bs-var';
@use 'styles/modules/text-helper';

.dvb-file-list {
    .entity {
        width: 100%;

        @include text-helper.text-overflow;
    }

    &.enabled {
        .draggable-element {
            cursor: move;

            .selected-entity:hover .entity {
                width: math.percentage(math.div(8, bs-var.$grid-columns));
            }
        }
    }

    .form-row {
        margin-bottom: 0;
    }

    .no-files {
        line-height: variables.$tall-line-height;
    }

    .add {
        .fa {
            vertical-align: middle;
        }

        line-height: variables.$tall-line-height;
    }
}
