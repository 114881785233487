@use 'styles/mixins';
@use 'styles/variables' as v;

.resource-hover {
    background-color: v.$color-main-bright;
    cursor: pointer;
}

.timeline {
    .badge {
        border-radius: 10px;
        padding: 3px 5px;
        font-weight: normal;
        font-variant: tabular-nums;
        pointer-events: auto;

        &.badge-success {
            background-color: v.$brand-success;
            color: v.$background-color;
        }

        &.badge-warning {
            background-color: v.$color-contrast-alt-normal;
            color: v.$background-color;
        }

        &.badge-danger {
            background-color: v.$brand-danger;
            color: v.$background-color;
        }

        &.badge-neutral {
            background-color: v.$color-main-middle;
            color: v.$background-color;
        }
    }
}

.dvb-timeline.scroll-wrapper {
    $timeline-spacing: 5px;

    .timeline-entry {
        display: flex;
        margin-bottom: $timeline-spacing;

        .dvb-timeline-entry-icon {
            height: 100%;
            width: v.$timeline-width;

            .timeline-icon {
                @include mixins.timeline-icon;
            }

            .line {
                height: calc(100% - #{v.$timeline-width});
                background-image: linear-gradient(v.$color-guide-normal, v.$color-guide-normal);
                background-size: 1px calc(100% - $timeline-spacing);
                background-repeat: no-repeat;
                background-position: center bottom;
            }
        }

        &.last .line {
            background: none;
        }

        .dvb-timeline-entry-details {
            width: calc(100% - #{v.$timeline-width} - #{v.$small-abstand});
            margin-left: v.$small-abstand;
            padding-bottom: v.$normal-abstand;

            .date-time {
                font-size: v.$font-size-small;
                margin-bottom: 4px;
            }
        }
    }
}
