@use 'styles/variables' as v;

.custom-node,
.angular-ui-tree-handle {
    margin-bottom: v.$small-abstand;
    padding: 10px;
    color: v.$color-guide-normal;
    background-color: v.$color-guide-bright;

    .btn {
        color: v.$color-guide-normal;

        &:hover {
            color: v.$color-guide-hover;
        }
    }
}

.angular-ui-tree-placeholder {
    display: block;
    width: 100%;
    background: v.$color-guide-bright;
}

.angular-ui-tree-drag {
    .angular-ui-tree-handle,
    .custom-node,
    .angular-ui-tree-handle:hover {
        color: v.$background-color;
        background-color: v.$color-guide-hover;

        .btn {
            color: v.$background-color;
        }
    }

    position: absolute;
    display: block;
    width: 60vw !important;
    z-index: 999;
    pointer-events: none;
    opacity: 0.8;
}
