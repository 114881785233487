@use 'styles/modules/text-helper';
@use 'styles/mixins';
@use 'styles/variables';

.dvb-search-entity {
    @include mixins.typeahead-default;

    a {
        position: relative;
    }
}
