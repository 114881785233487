@use 'styles/variables';

.dvb-rechnung-main {
    .dvb-rechnung-status {
        font-size: variables.$font-size-base;
        display: inline-block;
    }

    h1 {
        .fa {
            font-size: variables.$font-size-icon;
        }

        .custom-icon {
            font-size: variables.$font-size-base;
        }
    }
}
