@use 'styles/variables';

.dvb-betreuungsfaktor-icon {
    cursor: default;

    .betreuungsfaktor-icon {
        width: variables.$font-size-base;
        height: variables.$font-size-base;
    }

    .background-circle {
        fill: variables.$color-main-normal;
    }
}
