@use 'styles/mixins';
@use 'styles/variables';

.dvb-kind-leistungen-pro-monat {
    overflow: hidden;

    .leistungsmonat-heading,
    .tarifrechnung-result-title,
    .leistungsrechnung-summe {
        font-weight: variables.$bold-font-weight;
        line-height: variables.$line-height-normal;
    }

    .total-betrag p {
        font-weight: variables.$bold-font-weight;
    }

    .rechnungs-total p {
        white-space: nowrap;
    }

    .selected-leistungsrechnung-monat {
        background-color: variables.$color-main-bright;

        .tage {
            display: none;
        }

        .hover-only {
            display: flex;
            align-items: center;
        }
    }

    .leistungsrechnung-monat {
        border-bottom: variables.$border-default;
        padding: 0 variables.$small-abstand;
        display: flex;
        align-items: center;
        height: variables.$accordion-height;
        cursor: pointer;

        &:hover {
            @include mixins.colors-default;

            .hover-only {
                display: flex;
                align-items: center;
            }
        }

        .task-badge {
            display: inline-block;
            margin-left: variables.$smaller-abstand;
        }

        .mutation-badge {
            display: inline-block;
        }

        .tooltip-inner {
            max-width: 100%;
            max-height: 100%;
            white-space: pre-wrap;
        }

        .delete-badge {
            margin-left: variables.$smaller-abstand;
            padding: 0.5 * variables.$smaller-abstand;
        }
    }

    .detail {
        background-color: variables.$color-main-bright;
        padding: variables.$normal-abstand variables.$small-abstand;

        &.leistungsrechnung-summe {
            border-bottom: variables.$border-default;
            border-top: variables.$border-white;
        }

        .leistungsrechnung:not(:last-child) {
            margin-bottom: variables.$normal-abstand;
        }
    }
}
