@use 'styles/variables';
@use 'styles/modules/bs-var';

.dvb-dashboard-tasks {
    .panel-heading {
        display: flex;
        flex-direction: row;

        h2 {
            margin: 0 auto 0 0;
        }

        button {
            color: variables.$color-contrast-alt-bright-on-hover;
            margin-right: variables.$small-abstand;
        }

        .dropdown {
            ul.categories {
                padding: variables.$small-abstand variables.$normal-abstand;
                right: 0;
                left: auto;
                border: 1px solid white;
                color: variables.$color-main-hover;
            }
        }
    }

    .ort-filter {
        background: variables.$color-guide-bright;
        padding: variables.$small-abstand;

        .dvb-search-on-click-entity {
            align-self: flex-end;
            margin-left: auto;

            > a {
                float: right;
            }

            ul.dropdown-menu {
                padding: 0;
            }
        }

        .filter-option-list {
            clear: both;

            .filter-element .label-default:not(.active) {
                background-color: variables.$color-guide-middle;
            }
        }
    }

    .pages-wrapper {
        background-color: variables.$color-guide-bright;

        .dvb-pages {
            .dvb-page li:last-child {
                border-bottom: variables.$border-default-width variables.$border-default-style white;
            }

            .page-switcher {
                margin-top: variables.$small-abstand;
                padding: 0 variables.$small-abstand;
                text-align: center;

                .pagination {
                    margin: 0 0 variables.$small-abstand 0;
                    padding: 0;
                    float: none;

                    .pagination-page:not(.active) {
                        @media (min-width: variables.$container-md) and (max-width: variables.$screen-xl-min),
                            (max-width: bs-var.$screen-xs) {
                            display: none;
                        }
                    }

                    li a {
                        background-color: transparent;
                        border: none;
                    }

                    .active a {
                        color: variables.$color-guide-normal;
                    }
                }

                .items-per-page {
                    display: block;
                    clear: both;

                    select {
                        margin-bottom: variables.$small-abstand;
                        cursor: pointer;
                        color: variables.$color-guide-normal;
                        background-color: transparent;
                        border: none;
                        text-align: right;
                        font-weight: variables.$bold-font-weight;
                    }
                }
            }
        }
    }
}
