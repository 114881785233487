@use 'styles/variables';

@media print {
    // use the bootstrap class "hidden-print" to hide elements in the print layout

    body {
        // get rid of large padding that causes empty last pages
        padding-bottom: 0;
    }

    .header-row {
        margin-top: variables.$small-abstand;
    }

    .header-breadcrumb {
        display: none;
    }

    a[href='']::after {
        content: '';
    }
}
