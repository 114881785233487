@use 'styles/variables';

.dvb-kita-rechnungslauf-finish-kontaktperson-entry {
    .rechnungs-total {
        line-height: variables.$floating-text-line-height;
    }

    .row {
        background-color: variables.$color-main-bright;
    }

    .recipient,
    .vorauszahlung {
        vertical-align: middle;
    }
}
