@use 'styles/variables' as v;

.popover {
    border-radius: 0;
    box-shadow: none;
    max-width: none;
    padding: 0;

    .popover-filter {
        padding: v.$small-abstand;
        text-align: center;

        .datepicker {
            width: 140px;

            .dvb-datepicker-text-field {
                margin-bottom: 0;
            }
        }

        &.pensum {
            min-width: 140px;
        }
    }
}

.popover-title {
    border-radius: 0;
}

.popover-content {
    padding: 0;
}

.popover-body {
    margin: v.$small-abstand;
    max-width: 400px;

    p {
        line-height: v.$line-height-normal;
    }
}

// angular CDK overlay
.cdk-overlay-pane {
    background-color: v.$color-guide-bright;
    padding: v.$small-abstand;

    @mixin overlay-arrow($up: true) {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        @if $up {
            top: -9px;
            border-bottom: 10px solid v.$color-guide-bright;
        } @else {
            bottom: -9px;
            border-top: 10px solid v.$color-guide-bright;
        }
    }

    &.below::before {
        @include overlay-arrow(true);
    }

    &.above::after {
        @include overlay-arrow(false);
    }
}
