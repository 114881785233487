@use 'styles/variables';

.dvb-editable-text-area {
    textarea,
    textarea[disabled] {
        background-color: transparent;
        border: none;
    }

    &.dvb-click2edit-open {
        margin-left: 0;

        textarea {
            border: variables.$border-default;
        }
    }

    &[disabled]:hover {
        background-color: transparent;
        margin-left: -#{variables.$small-abstand + variables.$border-default-width};

        textarea {
            cursor: text;
        }
    }
}
