@use 'styles/variables';

.dvb-rechnungs-revision-error {
    .warning {
        color: variables.$color-contrast-normal;
    }
}

// Wegen Append-to-Body muss dies im globalen Scope sein
.dvb-rechnungs-revision-error-list {
    margin: variables.$small-abstand;
    padding-left: variables.$small-abstand;

    li {
        margin-bottom: variables.$smaller-abstand;
    }
}
