@use 'styles/variables';

.dvb-pages {
    .pagination {
        margin: 0 variables.$normal-abstand variables.$small-abstand 0;
        float: left;
    }

    .items-per-page {
        display: inline;
        white-space: nowrap;
        padding-right: variables.$small-abstand;

        select {
            display: inline;
            width: 3 * variables.$font-size-icon-large;
        }
    }
}
