@use 'styles/variables' as v;

.tox.tox-tinymce {
    font-family: v.$font-family-base;
    border: v.$border-default-width v.$border-default-style v.$color-main-bright;
    border-radius: 0;

    .tox-editor-container {
        .tox-edit-area::before {
            border: v.$border-default-width v.$border-default-style v.$input-border-focus;
            border-radius: v.$input-border-radius;
        }

        .tox-editor-header {
            box-shadow: none;
            border-bottom: v.$border-default-width v.$border-default-style v.$color-main-bright;

            .tox-tbtn {
                // stylelint-disable-next-line selector-class-pattern
                .tox-tbtn__select-label {
                    font-weight: v.$bold-font-weight;
                }

                // stylelint-disable-next-line selector-class-pattern
                &.tox-tbtn--enabled {
                    color: v.$background-color;
                    background-color: v.$color-guide-normal;

                    path {
                        fill: v.$background-color;
                    }
                }

                // stylelint-disable-next-line selector-class-pattern
                &:hover:not(.tox-tbtn--enabled) {
                    background-color: transparent;
                    color: v.$color-guide-normal;

                    path {
                        fill: v.$color-guide-normal;
                    }
                }
            }
        }
    }
}
