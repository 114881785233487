@use '../../directive/dvb-belegung/dvb-belegung';
@use 'styles/variables';

.bewerbung {
    @include dvb-belegung.belegung-container;

    .wochenplan .tag .belegungseinheiten .belegungseinheit .selected {
        background-color: variables.$color-main-middle !important;
    }

    .bemerkung,
    .anmeldedatum,
    .gemeinde {
        margin-top: variables.$normal-abstand;

        p {
            max-width: 100%;
        }
    }
}
