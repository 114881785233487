@use 'styles/modules/bs-var';
@use 'styles/variables';

.dvb-login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: bs-var.$zindex-modal + 1;
    background: variables.$background-color;

    &.login-state {
        // if we are on the login state, modal dialogs should be shown
        z-index: bs-var.$zindex-modal-background - 1;
    }

    .container {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}
