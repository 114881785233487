@use 'styles/variables';

.kita-kinder {
    .kinder > div:last-child {
        .eintrag {
            border-bottom: none;
        }
    }

    .kinder {
        h2 {
            margin-bottom: variables.$normal-abstand + variables.$small-abstand;
        }
    }

    .gruppen-wochen-belegung {
        margin-top: variables.$big-abstand;
    }

    .full-collapse {
        // reduce margin between fraktionen when full-collapse is available
        .gruppen-wochen-belegung {
            margin-top: variables.$normal-abstand;

            .collapse.in {
                .wochenplan {
                    // restore margin when fraktion is visible / not collapsed
                    margin-bottom: variables.$big-abstand;
                }
            }
        }
    }
}
