@use 'styles/variables';

.dvb-translatable-language {
    li {
        padding: 0 0 0 5px;
    }

    .btn-tab {
        padding: variables.$smaller-abstand;
        border-radius: 0;
        background-color: transparent;
    }

    .btn-tab:hover,
    .btn-tab:focus {
        color: variables.$color-main-normal;
        background-color: variables.$color-guide-bright;
    }

    .active {
        background-color: variables.$color-guide-bright;
        border-bottom: none;

        &:hover {
            color: variables.$color-main-normal;
        }
    }
}
