@use 'styles/mixins';
@use 'styles/variables';

.dvb-report-template-selector {
    .version-icon {
        // hacky: places the warning icon next to the select field, potentially on top of other elements
        position: absolute;
        line-height: variables.$tall-line-height;
        margin-left: -#{variables.$list-icon-width};
    }
}
