// Spacing

@use 'sass:math';

$-base: 7px;
$smaller-1x: $-base;
$small-2x: $-base * 2;
$three-3x: $-base * 3;
$normal-4x: $-base * 4;
$five-5x: $-base * 5;
$seven-7x: $-base * 7;
$big-10x: $-base * 10;
$tiny-half: math.ceil($-base * 0.5);

// gaps
$row-gap-y: $small-2x;
$column-gap-x: $small-2x;
$header-border: $three-3x;
$buttons-gap-y: $smaller-1x;

// padding
$padding-base-horizontal: $small-2x;
$padding-base-vertical: $small-2x;

@mixin padding($spacing-value, $spacing-sides) {
    @include -space-sides('padding', $spacing-sides, $spacing-value);
}

@mixin margin($spacing-value, $spacing-sides) {
    @include -space-sides('margin', $spacing-sides, $spacing-value);
}

@mixin -space-sides($property, $sides, $value) {
    @each $side in $sides {
        #{$property}-#{$side}: $value;
    }
}
