@use 'styles/mixins';
@use 'styles/variables';

.dvb-list-summary {
    @include mixins.label-colors-default;

    padding: variables.$smaller-abstand variables.$small-abstand;
    border-bottom: variables.$border-default;

    & > .row > div:last-child {
        padding-right: variables.$small-abstand;
    }
}
