@use 'styles/variables' as v;

.btn {
    border: none;
    box-shadow: none;

    &:focus,
    &:active,
    &:active:focus,
    &.active,
    &.active:focus {
        box-shadow: none;
        outline: none;
    }

    // stylelint-disable-next-line no-descending-specificity
    &:focus-visible {
        outline: solid 2px v.$color-guide-hover;
    }

    &-group.open .dropdown-toggle {
        box-shadow: none;
    }

    &-default {
        &:active {
            color: v.$color-main-bright-on-hover;
        }

        &:hover,
        &:focus,
        &:active,
        &:active:focus,
        &.active,
        &.active:focus {
            background-color: v.$color-main-hover;
        }
    }

    &-link {
        padding: 0;
        font-weight: v.$bold-font-weight;
        color: v.$link-color;

        &:hover,
        &:focus,
        &[disabled]:hover,
        &[disabled]:focus {
            color: v.$link-hover-color;
            text-decoration: v.$link-hover-decoration;
        }

        &-dropdown {
            padding: 0;

            &.btn-margin {
                padding: v.$padding-base-vertical v.$padding-base-horizontal v.$padding-base-vertical 0;
            }

            &.btn-margin-sm {
                padding: v.$smaller-abstand v.$smaller-abstand v.$smaller-abstand 0;
            }
        }
    }

    &-margin {
        padding: v.$padding-base-vertical v.$padding-base-horizontal;
    }

    &.label,
    &.label:hover,
    &.label:focus {
        color: v.$color-guide-hover;
    }
}
