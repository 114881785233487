@use 'styles/variables';

.dvb-fraktion-monatsblatt {
    .dvb-tasks-badge {
        margin: 0;
    }

    .footnote {
        font-size: small;
        vertical-align: text-top;
    }

    legend {
        font-size: variables.$font-size-base;
        margin-bottom: 0;
    }
}
