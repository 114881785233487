@use 'sass:math';
@use 'sass:color';
@use 'styles/modules/bs-var';
@use 'styles/variables' as v;

@mixin label-colors-selected() {
    color: v.$color-guide-bright-on-hover;
    background-color: v.$color-guide-normal;
}

// a mixin that creates a diagonal background pattern
@mixin nicht-verfuegbar-background() {
    background-image: linear-gradient(
            125deg,
            v.$color-contrast-bright 25%,
            transparent 25%,
            transparent 50%,
            v.$color-contrast-bright 50%,
            v.$color-contrast-bright 75%,
            transparent 75%,
            transparent
    );
    background-size: 10px 10px;
}

@mixin label-colors-default() {
    color: v.$color-guide-hover;
    background-color: v.$color-guide-bright;
}

@mixin colors-error() {
    color: v.$color-contrast-bright-on-hover;
    background-color: v.$color-contrast-normal;

    &:hover {
        background-color: v.$color-contrast-hover;
    }
}

@mixin colors-warning() {
    color: v.$color-contrast-hover;
    background-color: v.$color-contrast-bright;

    &:hover {
        background-color: v.$color-contrast-middle;
    }
}

@mixin colors-selected() {
    color: v.$color-main-bright-on-hover;
    background-color: v.$color-main-normal;
}

@mixin colors-default() {
    color: v.$color-main-normal;
    background-color: v.$color-main-bright;
}

@mixin colors-selected-editable() {
    &:not(.border-green, .border-red) {
        color: v.$color-guide-bright-on-hover;
        background-color: v.$color-guide-normal;

        &:hover {
            background-color: v.$color-guide-hover;
        }
    }
}

@mixin colors-contrast-alt() {
    color: v.$color-contrast-alt-bright-on-hover;
    background-color: v.$color-contrast-alt-normal;

    &:hover {
        background-color: v.$color-contrast-alt-hover;
    }
}

@mixin make-wider() {
    margin-right: -#{v.$small-abstand};
    margin-left: -#{v.$small-abstand};
    padding-right: v.$small-abstand;
    padding-left: v.$small-abstand;
    border-bottom: v.$border-white;
}

/* INFO: flex, short for (flex-grow, flex-shrink, flex-basis)
   current flex syntax default: 0 1 auto -> looks good by default
   tweener ie10 syntax default: 1 0 0px (positive-flex, negative-flex, preferred-size) -> looks bad by default,
   always set to: -ms-flex: 0 1 auto;
   tweener ie10 syntax default: no floating point numbers, INTEGER ONLY -> multiply by 100
   */
// stylelint-disable property-no-vendor-prefix
@mixin flex($grow, $shrink: 1, $basis: auto) {
    -ms-flex: math.round($grow * 100) math.max(1, math.floor($shrink)) $basis;
    -webkit-flex: $grow $shrink $basis;
    flex: $grow $shrink $basis;
}

// stylelint-enable property-no-vendor-prefix

@mixin error-input() {
    border-color: v.$state-danger-text;
    background-color: v.$background-color;
}

@mixin typeahead-default() {
    .input-group-addon {
        &:hover,
        &:active,
        &:focus {
            background-color: v.$color-main-hover;
        }

        border: none;
        color: v.$color-main-bright;
    }

    .dropdown-menu {
        // nur so breit machen wie das Input Feld, nicht unter das Such-Icon gehen
        width: calc(100% - (3 * #{v.$small-abstand}));
        background-color: transparent;
        padding: 0;
        overflow-y: auto;
        max-height: 400px;

        a {
            background-color: v.$color-main-bright;
            padding-left: v.$small-abstand;
            line-height: v.$tall-line-height;
            color: v.$color-main-hover;
        }

        a:hover,
        a:focus,
        .active > a {
            background-color: v.$color-main-middle;
            color: v.$color-main-hover;
        }

        a.disabled {
            background-color: color.adjust(v.$color-main-bright, $lightness: 7%) !important;
            color: rgba(v.$color-main-hover, 0.5);
            cursor: not-allowed;
        }
    }
}

@mixin selected-entity() {
    .selected-entity {
        padding: 0;
        line-height: v.$tall-line-height;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        overflow: hidden;

        .entity {
            font-weight: v.$bold-font-weight;
        }

        .edit {
            display: none;
            text-align: right;
        }

        &:hover {
            background-color: v.$color-main-bright;

            .edit {
                display: inherit;
            }
        }
    }
}

@mixin year-switcher-heading() {
    .dvb-date-switcher {
        margin-left: v.$normal-abstand;

        & > ul {
            margin-top: 0;
        }
    }

    h2,
    .dvb-date-switcher,
    .dvb-date-switcher:first-of-type ul {
        display: inline-block;
    }

    margin-bottom: v.$normal-abstand;
}

@mixin border-top() {
    border-top: v.$border-default;
}

@mixin abstand($prefix, $value) {
    @include abstand-side($prefix, $value, left);
    @include abstand-side($prefix, $value, right);
    @include abstand-side($prefix, $value, top);
    @include abstand-side($prefix, $value, bottom);
}

@mixin abstand-side($prefix, $value, $side) {
    .#{$prefix}-abstand-#{$side} {
        margin-#{$side}: $value;
    }
}

@mixin padding($prefix, $value) {
    @include padding-side($prefix, $value, left);
    @include padding-side($prefix, $value, right);
    @include padding-side($prefix, $value, top);
    @include padding-side($prefix, $value, bottom);
}

@mixin padding-side($prefix, $value, $side) {
    .#{$prefix}-padding-#{$side} {
        padding-#{$side}: $value;
    }
}

@mixin wochen-belegung() {
    .name {
        display: flex;

        h3 {
            margin-top: (2 * v.$font-size-base - math.ceil(0.75 * v.$font-size-base)) * 0.5;
            margin-right: auto;

            .fa {
                min-width: v.$font-size-base;
            }
        }

        .label {
            height: 2 * v.$font-size-base;
            background-color: v.$color-main-bright;
            color: v.$color-main-hover;
        }
    }

    .panel-heading {
        font-weight: v.$bold-font-weight;
        font-size: v.$font-size-base;
    }
}

@mixin strike-through() {
    background: v.$color-main-bright linear-gradient(
            to top right,
            rgb(0 0 0 / 0%) 0,
            rgb(0 0 0 / 0%) 47%,
            #fff 50%,
            rgb(0 0 0 / 0%) 53%,
            rgb(0 0 0 / 0%) 100%
    );
}

/**
 * Timeline / Wizard
 */
@mixin timeline-icon() {
    width: v.$timeline-width;
    height: v.$timeline-width;
    border-radius: 50%;
    background-color: v.$color-guide-normal;
    color: v.$background-color;
    text-align: center;

    i {
        line-height: v.$timeline-width;
    }
}

// Horizontal dividers
// Dividers (basically an hr) within dropdowns and nav lists
// copy of node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_nav-divider.scss
// copied because line-height-computed is assumed to be global

@mixin nav-divider($color: #e5e5e5) {
    height: 1px;
    margin: (math.div(bs-var.$line-height-computed, 2) - 1) 0;
    overflow: hidden;
    background-color: $color;
}
