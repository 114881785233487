@use 'modules/color';
@use 'sass:math';

$background-color: white;

// Belegungen & Wünsche
$status-und-name-flex-grow: 1.75;
$icons-flex-grow: 0.75;
$pensum-flex-grow: 0.75;
$plaetze-flex-grow: 1;
$kindergarten-flex-grow: 0.5;
$tage-flex-grow: 2;
$total-flex-grow: $status-und-name-flex-grow + $icons-flex-grow + $pensum-flex-grow + $plaetze-flex-grow + $kindergarten-flex-grow + $tage-flex-grow;

// Werden bei der Belegung und dem Belegungswunsch verwendet
$tageseinheit-cell-margin: 3px;
$small-tageseinheit-cell-height: 20px;
$tageseinheit-cell-height: 40px;

// region Bootstrap Variables - any variable overriding a default bootstrap variable must be listed in _bs-var
$state-danger-text: color.$color-contrast-normal;

// Line height
$line-height-base: 1;
$line-height-small: $line-height-base * 1.5;
$line-height-normal: $line-height-base * 1.25;
$headings-line-height: 1;
$floating-text-line-height: 2;
$tall-line-height: 3;

// Font
$font-size-base: 14px;
$font-size-icon: 16px;
$font-size-icon-large: 21px;
$font-size-large: math.ceil($font-size-base * 1.5);
$font-size-small: math.ceil($font-size-base * 0.75);
$font-size-tiny: math.ceil($font-size-base * 0.625);
$font-family-base: proxima-nova, sans-serif;
$normal-font-weight: 300;
$bold-font-weight: 600;

// Input
$input-color-placeholder: color.$color-main-middle;
$input-color: color.$color-main-normal;
$accent-color: color.$color-guide-hover;

// Border
$border-white: 2px solid white;
$border-default-width: 1px;
$border-default-style: solid;
$border-default-color: color.$color-main-middle;
$border-default: $border-default-width $border-default-style $border-default-color;

// Grid
$grid-gutter-width: 22px;
$screen-xl-min: 1440px;
$screen-lg-max: ($screen-xl-min - 1);
$container-xl: $screen-xl-min - $grid-gutter-width;
$container-lg: 1220px - $grid-gutter-width;
$container-md: 991px - $grid-gutter-width;
$container-sm: 720px + $grid-gutter-width; // bs3 default

// Navigation
$navbar-default-color: color.$color-guide-normal;
$navbar-default-link-color: color.$color-guide-normal;
$navbar-default-brand-hover-color: color.$color-guide-middle;
$navbar-default-link-hover-color: color.$color-guide-middle;
$navbar-default-link-active-color: color.$color-guide-normal;
$navbar-default-bg: color.$color-main-normal;
$navbar-border-radius: 0;
$navbar-default-border: 0;
$navbar-inverse-bg: color.$color-guide-hover;
$navbar-height: 4 * $font-size-base;
$navbar-font-size: $font-size-base * 1.5;
$navbar-padding-vertical: 0.5 * ($navbar-height - $navbar-font-size);
$navbar-collapse-max-height: initial;

// Titles
$font-size-h1: math.ceil(($font-size-base * 2.5));
$font-size-h2: math.ceil(($font-size-base * 1.5));
$font-size-h3: $font-size-base;
$headings-font-weight: 300;

// Abstände
$small-abstand: $font-size-base;
$smaller-abstand: 0.5 * $font-size-base;
$normal-abstand: 2 * $small-abstand;
$big-abstand: 5 * $small-abstand;
$padding-base-horizontal: $small-abstand;
$padding-base-vertical: $small-abstand;
$checkbox-padding: 20px; // bootstrap does not provide a variable for padding-left of label & checkboxes

// Buttons
$btn-default-bg: color.$color-main-normal;
$btn-default-color: color.$color-main-bright;
$btn-primary-color: color.$color-guide-bright-on-hover;
$btn-primary-bg: color.$color-guide-normal;
$btn-font-weight: $bold-font-weight;
$border-radius-base: 3px;
$btn-width-height-ratio: math.div(5, 3);
$btn-small-font-size: $font-size-small; // laut spezifikation wär's $font-size-tiny, ist aber zu klein
$btn-small-height: math.ceil(($floating-text-line-height * $btn-small-font-size));
$btn-small-width: ($btn-small-height * $btn-width-height-ratio);

// Forms
$input-border: color.$color-main-bright;
$input-border-focus: color.$color-main-middle;
$input-group-addon-bg: color.$color-main-normal;
$border-radius-small: 2px;
$input-border-radius: $border-radius-small;

// Dropdown
$dropdown-bg: color.$color-guide-bright;
$dropdown-link-color: color.$color-guide-normal;
$dropdown-link-hover-color: color.$color-guide-hover;
$dropdown-link-hover-bg: color.$color-guide-bright;
$dropdown-link-active-color: color.$color-guide-hover;
$dropdown-link-active-bg: color.$color-guide-bright;
$dropdown-divider-bg: color.$color-guide-normal;
$dropdown-inverse-bg: white;
$dropdown-button-color: color.$color-guide-normal;
$dropdown-button-color-hover: color.$color-guide-bright;
$dropdown-button-bg: color.$color-guide-middle;
$dropdown-button-bg-hover: color.$color-guide-normal;

// Popovers
$popover-bg: color.$color-guide-bright;
$popover-border-color: color.$color-guide-bright;

// Links
$link-color: color.$color-guide-normal;
$link-hover-color: color.$color-guide-hover;
$link-hover-decoration: none;

// Pagination
$pagination-bg: color.$color-guide-bright-on-hover;
$pagination-border: $border-default-color;
$pagination-hover-bg: color.$color-guide-bright-on-hover;
$pagination-hover-color: $link-hover-color;
$pagination-hover-border: $border-default-color;
$pagination-active-bg: color.$color-guide-normal;
$pagination-active-color: color.$color-guide-bright-on-hover;
$pagination-active-border: $border-default-color;
$pagination-disabled-color: color.$color-main-middle;
$pagination-disabled-bg: color.$color-main-bright-on-hover;
$pagination-disabled-border: $border-default-color;
$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;
$pager-hover-bg: $pagination-hover-bg;
$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;
$pager-disabled-color: color.$color-main-middle;

// Tooltips
$tooltip-bg: color.$color-main-hover;
$tooltip-arrow-color: color.$color-main-hover;

// Tables
$table-bg: transparent;
$table-bg-accent: color.$color-main-bright; // Background color used for `.table-striped`.
$table-bg-hover: color.$color-main-bright-on-hover; // Background color used for `.table-hover`.
$table-bg-active: $table-bg-hover;
$table-border-color: $border-default-color; // Border color for table and cell borders.

// Alerts
$alert-padding: $small-abstand;
$alert-border-radius: 0;
$alert-danger-bg: color.$color-contrast-middle;
$alert-danger-text: color.$color-main-normal;
$alert-danger-border: color.$color-contrast-middle;

// Brand & Progressbar
$brand-primary: color.$color-main-normal;
$brand-success: color.$color-guide-normal;
$brand-info: color.$color-guide-middle; // noch nicht UI mässig abgesegnet
$brand-warning: color.$color-contrast-bright; // noch nicht UI mässig abgesegnet
$brand-danger: color.$color-contrast-normal;

// endregion

// Misc
$timeline-width: 40px;
$h1-min-width: 65px;
$login-z-index: 5000;
$accordion-height: 5 * $font-size-base;
$list-icon-width: $font-size-base + $small-abstand;
