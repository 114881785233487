@use 'styles/mixins';
@use 'styles/variables';

.dvb-kind-kita-zuweisen {
    .time-input {
        text-align: center;
        margin-bottom: variables.$smaller-abstand;

        &.ng-invalid {
            @include mixins.error-input;
        }
    }

    .gruppen-wochen-belegung {
        margin-bottom: variables.$big-abstand;
    }

    &.full-collapse {
        .gruppen-wochen-belegung {
            // reduce margin between fraktionen when full-collapse is available
            margin-bottom: variables.$normal-abstand;

            &.uncollapsed {
                // revert reduction, when fraktion is visible
                margin-bottom: variables.$big-abstand;
            }
        }
    }
}
