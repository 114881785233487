@use 'styles/misc';

.dvb-platz-typen {
    &.list-inline {
        // bootstrap adds margin-left: -5px already
        // to make this element fit into existing lists nicely, we do the same for margin-right
        margin-right: -5px;

        @include misc.inline-icons;
    }
}
