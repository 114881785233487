// Text

@use 'sass:math';
@use 'bootstrap5/scss/mixins/text-truncate';
@use 'color';
@use 'spacing';

// font size definitions - only use in css modules & layout/scss & bootstrap/overrides
$size-base: 14px;
$size-small: math.ceil($size-base * 0.75); // 11
$-size-tiny: math.ceil($size-base * 0.625); // 9
$size-large: math.ceil($size-base * 1.5); // 21

// semantic font sizes - to use in components
$size-h1: math.ceil($size-base * 2.5);
$size-h2: $size-large;
$size-h3: $size-base;

// font weight definitions - only use in css modules & layout/scss & bootstrap/overrides
$weight-normal: 300;
$weight-bold: 600;

// semantic font weights - to use in components
$weight-subtitle: $weight-bold;
$weight-top-navigation: $weight-bold;

// text spacing
$spacing-top-h1: spacing.$big-10x;
$spacing-bottom-h1: spacing.$big-10x;
$spacing-bottom-h2: spacing.$normal-4x;

// line-height definitions - only use in css modules & layout/scss & bootstrap/overrides
$height-base: 1;
$-height-form-control-px: 16px;
$height-form-control: math.div($-height-form-control-px, $size-base); // 1.143

// semantic line-heights - to use in components (paragraphs, formcontrols, dropdown lists)
$height-floating-text: 2;

@mixin truncate-ellipsis-on-block-element($max-width, $display: inline-block) {
    // only works on block / inline-block
    display: $display;
    max-width: $max-width;

    @include text-truncate.text-truncate;

    > * {
        display: inline !important;
    }
}

@mixin strike-through() {
    background: color.$color-main-bright linear-gradient(
            to top right,
            rgb(0 0 0 / 0%) 0,
            rgb(0 0 0 / 0%) 47%,
            #fff 50%,
            rgb(0 0 0 / 0%) 53%,
            rgb(0 0 0 / 0%) 100%
    );
}
