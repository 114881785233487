@use 'styles/variables';

.dvb-extra-platz-creation {
    .extra-platz-entry {
        border-bottom: variables.$border-default;
        line-height: variables.$floating-text-line-height;
    }

    .warning {
        color: variables.$color-contrast-normal;
    }
}
