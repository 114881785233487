@use 'styles/variables';

.dvb-kita-kinder-aenderungen {
    .panel {
        margin: 0;
    }

    .panel-heading {
        background-color: variables.$color-guide-middle;
        color: variables.$color-main-normal;
    }
}
