@use 'styles/modules/bs-var';

.dvb-search-on-click-entity {
    line-height: bs-var.$input-height-base;

    .dvb-search-entity {
        padding-bottom: 0;

        .input-group {
            margin-bottom: 0;
        }
    }
}
