@use 'styles/variables' as v;

.timeline .timeline-event {
    &.fraktion-independent {
        width: calc(100% + 2px);
        height: calc(100% - 6px);
        margin: 3px 0 0 -1px;
        outline: 2px dashed;
    }

    &.full-day-blocks {
        margin: 4px;

        &.fraktion-independent {
            width: calc(100% - 6px);
            margin: 3px 0 0 3px;
        }
    }

    &.nicht-verfuegbar {
        height: 100%;
        margin: 0;
        background-color: v.$color-main-bright;
    }
}
