@use 'styles/mixins';
@use 'styles/variables';

.dvb-wochen-plaetze {
    @include mixins.label-colors-default;

    .platz {
        padding: variables.$normal-abstand;
    }
}
