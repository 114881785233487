@use 'styles/variables';

.dvb-dashboard-greeting {
    background-color: variables.$color-guide-hover;
    color: variables.$color-guide-bright-on-hover;

    .container {
        padding: 0 (2 * variables.$font-size-base);
    }

    .date {
        display: table;
        padding: variables.$small-abstand;
        font-size: variables.$font-size-h2;
        font-weight: variables.$bold-font-weight;
        background-color: variables.$color-guide-normal;
        text-align: center;
        list-style: none;

        li {
            letter-spacing: 1px;
            text-transform: uppercase;
            margin: 0;
        }

        .divider {
            height: variables.$border-default-width;
            width: 100%;
            background-color: variables.$color-guide-bright;
            margin-top: variables.$small-abstand;
            margin-bottom: variables.$small-abstand;
        }
    }
}
