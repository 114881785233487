@use 'sass:math';
@use 'styles/modules/bs-var';
@use 'styles/mixins';
@use 'styles/variables' as v;

$padding-vertical: v.$padding-base-vertical - 1px;
$padding-horizontal: v.$padding-base-horizontal - 1px;
$padding-sm: v.$smaller-abstand - 1px;

@mixin form-control {
    background-color: v.$color-main-bright;
    box-shadow: none;
    padding: $padding-vertical $padding-horizontal;
    margin-bottom: v.$normal-abstand;

    &:focus {
        background-color: v.$background-color;
        box-shadow: none;
    }

    &.input-sm {
        padding: $padding-sm;
    }
}

.form-control {
    @include form-control;
}

textarea {
    // For unknown reasons, the hidden textarea used for height calculation of elastic textareas is missing the
    // line-height when used in an angular upgraded component...
    line-height: v.$floating-text-line-height;

    &.form-control {
        $padding: (0.5 * v.$font-size-base) - 1;

        line-height: v.$floating-text-line-height;
        padding-top: $padding;
        padding-bottom: $padding;

        &[msd-elastic] {
            resize: none;

            // Fix for IE 11
            // The elastic <textarea> elements got a size that was too small
            // see: https://github.com/monospaced/angular-elastic/issues/58#issuecomment-111152108
            min-height: (v.$font-size-base * v.$floating-text-line-height) + ($padding * 2);
        }
    }
}

select.form-control {
    padding: 0 (v.$padding-base-horizontal - 1px);

    &.select-sm {
        padding: 4px;
        height: 2 * v.$font-size-base;
    }
}

label {
    font-weight: inherit;
}

.radio {
    label {
        line-height: v.$font-size-large;
    }

    &-help-block {
        font-size: v.$font-size-small;
    }

    &-inline {
        input[type='radio'] {
            margin-top: 0;
        }
    }
}

.checkbox {
    min-height: bs-var.$input-height-base - (2 * 10px);

    label {
        line-height: v.$font-size-large;
    }

    // adds padding to set content below a checkbox to the same indent as the checkboxes' label
    &-indent {
        // got to take into accout the border of the form control
        padding-left: v.$checkbox-padding + v.$border-default-width;
    }
}

.input-group-btn button {
    line-height: v.$font-size-icon;
}

dvlib-click-2-edit {
    .form-control {
        margin-bottom: 0;
        padding-bottom: 12px;
    }

    textarea.form-control {
        padding-top: 8px;
    }
}

.click2edit,
.dvb-click2edit-open {
    margin-bottom: 0;

    input,
    textarea {
        margin-bottom: 0;
    }

    &.multi-line {
        height: auto;
    }
}

.click2edit {
    color: inherit;
    width: auto;
    border-color: transparent;
    background-color: transparent;
    margin-left: - (v.$small-abstand + v.$border-default-width);

    &:hover {
        border-color: v.$input-border;
        background-color: v.$color-main-bright;
        margin-left: 0;
    }
}

[disabled] .click2edit:hover {
    // disable hover effect for disabled click2edit elements
    border-color: transparent;
    background-color: transparent;
    margin-left: -#{v.$small-abstand + v.$border-default-width};
}

.dvb-editable-label.ng-empty .click2edit,
label.is-empty,
.is-empty p,
.is-empty span {
    font-style: normal;
    color: v.$input-color-placeholder;
}

// disable autofill effect (the yellow background)
input:-webkit-autofill {
    box-shadow: 0 0 0 100px v.$color-main-bright inset;
    -webkit-text-fill-color: v.$color-main-normal;

    &:focus {
        box-shadow: 0 0 0 100px v.$background-color inset;
    }
}

form, .form-container {
    .error-message {
        display: none;
    }

    &.ng-submitted {
        input,
        select,
        textarea {
            &.ng-invalid {
                @include mixins.error-input;
            }
        }

        .ng-invalid {
            &.highlight-input-fields-when-invalid {
                input,
                select {
                    @include mixins.error-input;
                }
            }
        }

        .error-message {
            display: inherit;
            line-height: v.$floating-text-line-height;
            color: v.$color-contrast-hover;
        }
    }
}

.dvb-form {
    .form-row {
        margin-bottom: v.$normal-abstand;
    }

    .form-section-minus-normal-abstand {
        margin-bottom: v.$big-abstand - v.$normal-abstand;
    }

    .form-section {
        margin-bottom: v.$big-abstand;
    }

    @include mixins.selected-entity;
}

.form-linker {
    line-height: v.$tall-line-height;
}

.label {
    $padding-side: 0.5 * (2 * v.$font-size-base - math.ceil(0.75 * v.$font-size-base));

    font-size: math.ceil(0.75 * v.$font-size-base);
    padding: $padding-side v.$font-size-base;
    border-radius: 2px;
    display: inline-block;
    letter-spacing: 0.4px;

    &.label-default.label-radio.active {
        @include mixins.label-colors-selected;

        &:hover {
            background-color: v.$color-guide-hover;
        }
    }

    &.label-radio:hover {
        cursor: pointer;
    }

    &.round {
        border-radius: v.$font-size-base;
    }

    &-default {
        @include mixins.label-colors-default;

        &[href]:hover,
        &[href]:focus,
        &:hover,
        &:focus {
            background-color: v.$color-guide-middle;
        }
    }
}

input[type='time'].form-control {
    font-variant-numeric: tabular-nums;
}
