$white: #fff;
$grey: #eee;
$slate: #777;

// Main Color
$color-main: hsl(210deg 15% 19%) !default;
$color-main-hover:  hsl(210deg 15% 19%) !default;
$color-main-normal: hsl(210deg 15% 26%) !default;
$color-main-middle: hsl(210deg 10% 70%) !default;
$color-main-bright: hsl(210deg 15% 90%) !default;
$color-main-bright-on-hover: white !default;

// Guide Color
$color-guide: hsl(165deg 34% 45%) !default;
$color-guide-active:  hsl(165deg, 34%, 36%) !default; // entspricht automatischer bs3 button:active background berechnung
$color-guide-focus:   hsl(165deg, 34%, 43%) !default; // entspricht automatischer bs3 button:focus background berechnung
$color-guide-hover:   hsl(165deg 34% 45%) !default;
$color-guide-normal:  hsl(165deg 34% 53%) !default;
$color-guide-middle:  hsl(165deg 34% 78%) !default;
$color-guide-bright:  hsl(165deg 34% 90%) !default;
$color-guide-bright-on-hover: white !default;

// Contrast Color
$color-contrast: hsl(359deg 100% 67%) !default;
$color-contrast-hover:  hsl(359deg 100% 67%) !default;
$color-contrast-normal: hsl(359deg 100% 74%) !default;
$color-contrast-middle: hsl(359deg 100% 87%) !default;
$color-contrast-bright: hsl(359deg 100% 94%) !default;
$color-contrast-bright-on-hover: white !default;

// Alternative contrast color
$color-contrast-alt-hover:  hsl(37deg 54% 53%) !default;
$color-contrast-alt-normal: hsl(37deg 77% 69%) !default;
$color-contrast-alt-middle: hsl(37deg 100% 82%) !default;
$color-contrast-alt-bright: hsl(37deg 100% 90%) !default;
$color-contrast-alt-bright-on-hover: white !default;
