@use 'styles/variables';

.dvb-rechnung-status {
    .status {
        &.status-not-ok {
            background-color: variables.$color-contrast-hover;
        }
    }

    i {
        display: inline-block;
    }
}
