@use 'styles/mixins';
@use 'styles/variables';

.dvb-tarif-verlauf-header {
    display: flex;

    .gueltigkeit {
        @include mixins.flex($grow: variables.$status-und-name-flex-grow + variables.$icons-flex-grow);

        width: 0;
        display: flex;
        flex-direction: column;
    }

    .template {
        @include mixins.flex($grow: variables.$total-flex-grow - variables.$status-und-name-flex-grow - variables.$icons-flex-grow);

        width: 0;
    }
}
