@use 'styles/mixins';
@use 'styles/misc';
@use 'styles/variables';

@mixin belegung-container {
    display: flex;

    .full-width-inline-list {
        @include misc.list-inline-row;

        width: 100%;

        .dropdown-menu {
            margin-top: variables.$small-abstand;
        }

        .align-right {
            margin-left: auto;
            padding-right: 0;

            // bootstrap shifts the whole ul block 5px to the left
            margin-right: -5px;
        }
    }

    .action-header {
        @include mixins.flex($grow: variables.$status-und-name-flex-grow + variables.$icons-flex-grow);

        width: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .actions {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            ul {
                margin-bottom: 0;
            }
        }
    }

    .group-container {
        @include mixins.flex($grow: variables.$total-flex-grow - variables.$status-und-name-flex-grow - variables.$icons-flex-grow);

        width: 0;

        .container-item {
            .wochenplan-wrapper,
            > .wochenplan {
                @include mixins.flex($grow: variables.$tage-flex-grow);

                margin: 0;
                width: 0;
            }

            display: flex;

            .description {
                @include mixins.flex($grow: variables.$pensum-flex-grow + variables.$plaetze-flex-grow + variables.$kindergarten-flex-grow);

                width: 0;

                .pensum {
                    display: flex;
                    line-height: variables.$floating-text-line-height;

                    div {
                        @include mixins.flex($grow: variables.$pensum-flex-grow + variables.$plaetze-flex-grow);

                        width: 0;
                    }
                }

                .pensum-plaetze-und-kindergarten {
                    display: flex;
                    line-height: variables.$floating-text-line-height;

                    .pensum {
                        @include mixins.flex($grow: variables.$pensum-flex-grow);

                        width: 0;
                    }

                    .plaetze {
                        @include mixins.flex($grow: variables.$plaetze-flex-grow);

                        width: 0;
                    }

                    .kindergarten {
                        @include mixins.flex($grow: variables.$kindergarten-flex-grow);

                        width: 0;
                    }
                }
            }
        }

        .tooltip-inner {
            max-width: 500px;
            white-space: pre-wrap;
        }

        .bemerkung {
            margin-top: variables.$normal-abstand;

            p {
                max-width: 100%;
            }
        }
    }
}

.dvb-belegung {
    @include belegung-container;
}
