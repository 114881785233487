@use 'styles/variables';

.dvb-kind-zuweisen {
    .kind {
        .kind-details {
            h2,
            ul {
                display: inline;
            }

            ul {
                margin: variables.$small-abstand;
            }
        }
    }

    .geb-und-prio > * {
        font-size: variables.$font-size-base;
        white-space: nowrap;
    }

    .no-bottom-margin .dvb-datepicker-text-field {
        margin-bottom: 0;
    }

    .border-top {
        border-top: variables.$border-default;
    }
}
