@use 'styles/variables';

.dvb-kita-rechnungslauf-erstellen {
    h2,
    .dvb-date-switcher,
    .dvb-date-switcher:first-of-type ul {
        display: inline-block;
    }

    .dvb-date-switcher {
        margin-left: variables.$normal-abstand;
    }
}
