@use 'styles/variables';
@use 'styles/forms';
@use 'styles/modules/bs-var';

.dvb-custom-field-values {
    .select-label {
        position: absolute;
        padding-left: forms.$padding-horizontal;
        color: variables.$input-color-placeholder;
        pointer-events: none;
        line-height: bs-var.$input-height-base;
    }

    .small {
        margin-bottom: variables.$small-abstand;

        select {
            margin-bottom: 0;
        }

        .select-label {
            padding-left: forms.$padding-sm;
            line-height: bs-var.$input-height-small;
        }
    }
}
