@use 'styles/modules/bs-var';
@use 'styles/variables' as v;

$abstand-filter: 10px;
$abstand-filter-items: 2px;

@mixin filter-li() {
    vertical-align: top;
    padding-right: $abstand-filter-items;
    padding-left: 0;
    display: block;
    float: left;
}

.filter {
    &-bar {
        padding-bottom: v.$small-abstand;
        border-bottom: v.$border-default;

        .dvb-date-switcher .header-date {
            margin-top: 0;
        }
    }

    &-toggle-buttons {
        & > li {
            padding: 0 $abstand-filter-items 0 0;
        }
    }

    &-flex {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
}

.filter-list {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .list-inline !optional; // kommt aus bootstrap-sass/.../_type.scss

    display: flex;
    flex-wrap: wrap;
    gap: $abstand-filter;
    align-items: baseline;
    margin: 0;

    .contents {
        display: contents;
    }

    &.small-abstand-top {
        margin-top: $abstand-filter;
    }

    &.small-abstand-bottom {
        margin-bottom: $abstand-filter;
    }

    .dvb-date-switcher .header-date .date-navigation {
        margin-bottom: 0;
    }

    .filter,
    > li {
        display: flex;
        align-items: baseline;
        gap: $abstand-filter;
        padding: 0;
    }

    li {
        list-style: none;
    }

    &.column {
        flex-direction: column;
        align-items: flex-start;
    }

    .pull-right {
        margin-left: auto;
    }
}

.filter-option-list {
    display: flex;
    flex-wrap: wrap;
    gap: $abstand-filter-items;
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        padding: 0;
    }
}

.dvb-kita-faktura-rechnungen-status-filter {
    display: flex;
    flex-direction: column;
    gap: $abstand-filter;
}
