@use 'styles/mixins';
@use 'styles/variables';

.dvb-accordion {
    .accordion-header,
    .accordion-footer {
        border-bottom: variables.$border-default;
        padding: 0 variables.$small-abstand;
        display: flex;
        align-items: center;
        height: variables.$accordion-height;

        // often a list-inline is used in the footer to display some action buttons or navigation links.
        // This quickfix makes sure the buttons / links are nicely centered
        .list-inline,
        .accordion-height {
            line-height: variables.$accordion-height;
        }
    }

    .accordion-header {
        font-weight: variables.$bold-font-weight;
        outline: none;
        line-height: variables.$line-height-normal;

        &:not(.accordion-read-only):hover {
            @include mixins.colors-default;

            cursor: pointer;
        }
    }

    .selected-accordion,
    .accordion-detail,
    .accordion-footer {
        background-color: variables.$color-main-bright;
    }

    .accordion-detail {
        padding: variables.$normal-abstand variables.$small-abstand;
        border-bottom: variables.$border-default;

        .click2edit {
            background-color: transparent;
        }

        .form-control:not([disabled]),
        .click2edit:hover,
        .dvb-adresse .click-to-edit-adresse .dvb-adresse-display:hover {
            background-color: variables.$background-color;
            color: variables.$color-main;
        }
    }

    .selected-entity {
        &:hover {
            background: variables.$color-contrast-bright-on-hover;
        }
    }
}
