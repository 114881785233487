@use 'styles/variables';

.overlay-spinner {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    opacity: 1;
    font-size: 3 * variables.$font-size-base;
}

.spinner-container {
    will-change: opacity;

    &.spinning {
        opacity: 0.4;

        .overlay-spinner {
            opacity: 1;
        }
    }
}
