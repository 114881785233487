@use 'styles/mixins';
@use 'styles/variables' as v;

html {
    accent-color: v.$accent-color;
}

// Selection
::selection {
    background-color: v.$color-guide-bright;
}

// Titles
h1 {
    margin-top: v.$big-abstand;
    margin-bottom: v.$big-abstand;
}

h2 {
    margin-top: 0;
    margin-bottom: v.$normal-abstand;
}

h3 {
    margin-top: 0;
    font-weight: v.$bold-font-weight;
}

// Standard Font-Weight
strong,
b {
    font-weight: v.$bold-font-weight;
}

// Margin
@include mixins.abstand(small, v.$small-abstand);
@include mixins.abstand(smaller, v.$smaller-abstand);
@include mixins.abstand(normal, v.$normal-abstand);
@include mixins.abstand(big, v.$big-abstand);

.no-margin {
    margin: 0 !important;
}

// Padding
@include mixins.padding(small, v.$small-abstand);
@include mixins.padding(smaller, v.$smaller-abstand);
@include mixins.padding(normal, v.$normal-abstand);
@include mixins.padding(big, v.$big-abstand);

.no-padding {
    padding: 0;
}

// Flew
.flex-grow-1 {
    @include mixins.flex($grow: 1);
}

// Paragraphs
p {
    line-height: v.$floating-text-line-height;
    margin: 0;
}

// Links
a {
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        outline: none;
    }

    &:focus-visible {
        outline: solid 2px v.$color-guide-hover;
    }

    font-weight: v.$bold-font-weight;
    text-decoration: none;
}

// Footer
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: v.$color-guide-bright;
    color: v.$color-main-normal;
    text-align: center;
    font-weight: v.$bold-font-weight;
    padding: v.$normal-abstand 0;
}

// Login dialog
.login {
    z-index: v.$login-z-index;
}
