@use 'styles/mixins';
@use 'styles/variables';

.dvb-rechnungs-konfigurationen {
    .rechnungs-konfigurationen {
        > :first-child {
            @include mixins.border-top;
        }
    }

    .disabled-label {
        &::before {
            content: '(';
        }

        &::after {
            content: ')';
        }

        font-size: variables.$font-size-small;
    }
}
