@use 'styles/variables';

.dvb-dashboard-favoriten {
    .add-favorit-form {
        flex-grow: 0;

        .dvb-dashboard-favoriten-suche {
            min-width: 400px;
        }
    }

    .header-link {
        line-height: variables.$tall-line-height;
    }

    h2 {
        margin-bottom: 0;
    }

    .favorit-types {
        display: flex;
        flex-wrap: wrap;
    }

    .favorit {
        margin: variables.$small-abstand variables.$small-abstand 0 0;
        max-width: 250px;

        .delete-favorit {
            margin-left: variables.$smaller-abstand;
            padding: 0.5 * variables.$smaller-abstand;
        }
    }
}
