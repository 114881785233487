@use 'styles/variables' as v;
@use 'styles/forms';

ngx-colors {
    .app-color-picker {
        .preview {
            box-shadow: none !important;
            border: v.$border-default;
        }
    }
}

#ngx-colors-overlay {
    .opened {
        border-radius: 0;

        button,
        input,
        p {
            font-family: v.$font-family-base;
            font-size: v.$font-size-base !important;
            letter-spacing: normal !important;
        }

        .manual-input-wrapper {
            .g-input {
                border: none;
                border-radius: 0;

                input {
                    @include forms.form-control;

                    color: v.$color-main-normal;
                    border-radius: 2px;

                    &:focus {
                        border: v.$border-default;
                    }
                }
            }
        }

        .nav-wrapper {
            button {
                color: v.$color-guide-normal;
                font-weight: 600;

                &:hover {
                    color: v.$color-guide-hover;
                    background-color: transparent;
                }
            }
        }
    }
}
