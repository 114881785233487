@use 'styles/variables';

.dvb-kibon-verfuegung-matching-assign {
    .heading-list {
        font-weight: variables.$bold-font-weight;
        line-height: 44px;
    }

    li {
        padding: 0;
    }
}
