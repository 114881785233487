@use 'styles/variables';

.dvb-draggable-dropzone {
    border: 1px dashed lightgray;
    align-items: center;
    justify-content: center;
    margin-bottom: variables.$small-abstand;
    display: block;

    &.active {
        border: 2px dashed lightgray;
    }

    .on-dashed-line {
        position: absolute;
        margin-top: -12px;
        margin-left: 12px;
    }

    .separator {
        border-top: 1px dashed lightgray;
    }

    .fa {
        color: lightgray;
        opacity: 1;
        background-color: white;
        padding-left: 5px;
        padding-right: 5px;
    }
}
