@use 'styles/variables';
@use 'styles/mixins';

.dvb-zuweisen-popover {
    display: none;

    .popover-content {
        padding: variables.$normal-abstand variables.$normal-abstand variables.$small-abstand;

        .divider {
            @include mixins.nav-divider(variables.$dropdown-divider-bg);

            margin-bottom: variables.$small-abstand;
        }
    }
}
