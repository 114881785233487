@use 'styles/mixins';
@use 'styles/variables';

.collapsible-panel-container {
    padding: variables.$normal-abstand 0;
    border-bottom: variables.$border-default;
    display: flex;

    &.compact-panel {
        border-bottom: none;
        padding: 0;
        line-height: variables.$floating-text-line-height;
    }

    &.last-container {
        border-bottom: none;
    }

    .folding-icon {
        position: absolute;
    }

    .header-and-content {
        @include mixins.flex($grow: 1);

        margin-left: variables.$list-icon-width;
        max-width: calc(100% - #{variables.$list-icon-width});
    }
}
