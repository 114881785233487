@use 'styles/mixins';
@use 'styles/variables';

.dvb-iso-locale {
    @include mixins.typeahead-default;

    .dropdown-menu {
        // Fix, um die Suchresultate auf die Breite des Inputs auszuweiten (noetig da keine btn-group verwendet wurde)
        padding: 0;
        width: calc(100% - #{variables.$grid-gutter-width});
    }
}
