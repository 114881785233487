@use 'styles/modules/bs-var';
@use 'styles/variables';

// Custom Styling of UI Boostrap Modal

body.modal-open {
    // hack to hide scrollbar of body
    max-height: 0;
}

// center content vertically
.dv-modal-window {
    display: flex;
    align-items: center;
    justify-content: center;

    // bootstrap adds a default top/bottom margin of 30px each
    min-height: calc(100% - 60px);

    // make sure to give the content space for elements with the .row class
    // without this, they create a vertical scrollbar
    .modal-content {
        padding: 0 calc(#{bs-var.$grid-gutter-width} / 2)
    }
}

.dvb-modal-container,
modal-container {
    position: fixed;
    inset: 0;
    background-color: variables.$background-color;

    // allow vertical scrolling
    overflow-y: auto;
}

.dvb-modal-backdrop,
.modal-backdrop {
    background-color: variables.$background-color;

    &.in {
        opacity: 1;
    }
}

.dvb-modal-window,
.dv-modal-window {
    .modal-content {
        background-color: transparent;
        box-shadow: none;
        border: none;
        overflow-x: auto;
        width: 100%;
        padding: variables.$small-abstand;
    }

    .modal-body,
    .modal-footer {
        text-align: center;
        padding: 0;
    }

    .modal-footer {
        border-top: 0;
    }
}

.dvb-modal-window.full-width .modal-dialog,
.dv-modal-window.full-width {
    @media (min-width: bs-var.$screen-md-min) {
        min-width: 900px;
    }

    width: auto;
    min-width: 90vw;
    max-width: 100vw;
}
