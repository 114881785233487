@use 'styles/variables' as v;

.dvb-gruppe-stammdaten {
    .select {
        background: white;
        border: none;
        padding-left: 0;
        margin-left: -5px;
    }

    .interactable-select {
        &:hover,
        &:focus {
            border-color: v.$color-main-bright;
            background: v.$color-main-bright;
            padding-left: 13px;
            margin-left: 0;

            option {
                background: white;
            }
        }
    }
}
