@use 'styles/variables' as v;

.panel-list-container {
    .panel-body-footer {
        max-height: 450px;
        overflow-y: auto;
    }

    .panel-body {
        padding: 0;
    }

    .panel-subtitle {
        background-color: v.$color-guide-bright;
        border-top: 1px solid v.$color-guide-bright-on-hover;
        color: v.$color-main;
        display: block;
        font-weight: v.$bold-font-weight;
        font-size: v.$font-size-small;
        padding: v.$smaller-abstand v.$small-abstand;
        border-bottom: 1px solid v.$color-guide-bright-on-hover;
    }

    .panel-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .list-item {
            background-color: v.$color-guide-bright;
            border-top: 1px solid v.$color-guide-bright-on-hover;
            color: v.$color-main;
            display: block;
            font-weight: v.$normal-font-weight;
            padding: v.$small-abstand;

            .checkbox {
                height: auto;
                margin: 0;
            }

            &:hover {
                background-color: v.$color-guide-middle;
            }
        }

        &.scrollable {
            overflow-y: auto;
            max-height: 300px;
        }

        &.with-header .list-item:first-child {
            border-top: 0;
        }
    }
}
