@use 'color';
@use 'spacing';
@use 'border';
@use 'text';
@use 'responsive';

.whitescreen {
    position: absolute;
    margin: 0;
    box-shadow: 0 0 20px 0 color.$slate;
    font-size: text.$size-base;
    border-width: 0;
    padding: 0;
    z-index: 9999;
    background: color.$color-guide-middle;

    top: 0;
    left: 0;
    width: 100svw;

    @include responsive.breakpoint-up(lg) {
        top: 5svh;
        left: 50%;
        transform: translateX(-50%);
        width: min(600px, 95vw);
    }

    p {
        margin: 0 0 spacing.$smaller-1x;
    }

    &-top {
        background: color.$color-guide-normal;
        color: color.$white;
        padding: spacing.$padding-base-horizontal;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: spacing.$normal-4x;
    }

    &-icon {
        font-size: text.$size-h1;
        line-height: initial;
    }

    &-title {
        padding-top: spacing.$padding-base-horizontal;
        line-height: text.$size-large;
    }

    &-message {
        line-height: text.$size-large;
    }

    &-email {
        padding-top: spacing.$padding-base-horizontal;
        color: color.$white;
        line-height: text.$size-large;

        a {
            color: color.$white;
        }

        a:hover {
            color: color.$color-main;
        }
    }

    &-bottom {
        padding: spacing.$padding-base-horizontal;

        p {
            font-size: text.$size-small;
            color: color.$color-main;
            word-wrap: anywhere;
        }
    }
}
