@use 'styles/mixins';
@use 'styles/variables';

.dvb-monats-belegung {
    .empty-day {
        background-color: variables.$color-main-bright;
    }

    .time-input {
        width: 60px;
        text-align: center;
    }

    .reduce-width {
        // Reduce the width of the column to the minimum required space based on contents.
        // Setting the width to 0 does not work in all browsers, so => falling back to 1px.
        width: 1px;
    }

    .custom-fields td,
    .no-border-top {
        border-top: none;
    }

    .table > tbody > tr > td {
        line-height: 2;
    }

    .table > thead > tr > th {
        font-weight: variables.$normal-font-weight;
        border: 0;
    }

    &:not(.readonly) input.ng-invalid {
        @include mixins.error-input;
    }

    @media print {
        .form-control.input-sm {
            padding: 0;
            height: 15px;
        }

        .table > tbody > tr > td {
            line-height: 1;
            padding: 4px;
        }
    }

    .warning {
        color: variables.$color-contrast-normal;
    }

    // by default, custom fields are not wrapped. Which breaks the layout in this component.
    .custom-fields .dvb-editable-label {
        .form-control {
            white-space: normal;
            height: auto;
        }
    }
}
