@use 'styles/modules/bs-var';
@use 'styles/modules/text-helper';
@use 'styles/mixins';
@use 'styles/variables';

.search-input {
    width: 100%;

    .navbar-form {
        margin: 0;

        .input-group {
            width: 100%;

            .form-control {
                font-size: variables.$font-size-large;

                &:focus {
                    border-color: variables.$color-guide-normal;
                    background-color: variables.$background-color;
                }

                border-radius: 0;
                height: variables.$navbar-height;
                background-color: variables.$color-guide-bright;
            }

            .input-group-btn {
                width: variables.$navbar-height;
            }

            .btn-primary {
                &:hover,
                &:active,
                &:focus {
                    box-shadow: none;
                }

                height: variables.$navbar-height;
                width: variables.$navbar-height;
                border-radius: 0;
            }

            .dropdown {
                width: 0;

                .header-box-attachment {
                    margin-left: -38px;
                    z-index: 3;
                    position: relative;
                }
            }

            .search-entities {
                border: 1px solid white;
                border-top: transparent;
            }

            .dropdown-menu:not(.search-entities) {
                font-size: variables.$font-size-large;
                padding: 0;
                width: calc(100% - #{variables.$navbar-height});
                background-color: transparent;
                max-height: calc(100vh - #{variables.$navbar-height});
                overflow: auto;

                a {
                    background-color: variables.$color-guide-bright;
                    padding: 0 variables.$small-abstand;
                    line-height: variables.$navbar-height;
                    color: variables.$color-main-hover;
                    position: relative;
                    height: variables.$navbar-height;

                    > * {
                        display: inline-block;

                        @include text-helper.text-overflow;

                        line-height: variables.$navbar-height;
                        float: left;
                    }

                    .search-result-label {
                        max-width: 70%;
                        padding-left: variables.$small-abstand;
                    }

                    .badge {
                        @include mixins.label-colors-default;

                        $margin: ((variables.$navbar-height - variables.$font-size-base) - (2 * 3px)) * 0.5;

                        right: 0;
                        position: absolute;
                        margin: $margin;
                        max-width: calc(30% - (2 * $margin));
                        line-height: variables.$font-size-base;
                        background-color: variables.$color-guide-bright-on-hover;
                    }
                }

                .active {
                    a {
                        background-color: variables.$color-guide-middle;
                        color: variables.$color-main-hover;
                    }
                }
            }

            ul.search-entities {
                padding: variables.$small-abstand variables.$normal-abstand;
                margin-right: 1px;
                right: 0;
                left: auto;

                // have to re apply default styling because bootstrap overrides checkbox styling in the navigation
                .checkbox {
                    label {
                        padding-left: variables.$checkbox-padding;

                        input[type='checkbox'] {
                            position: absolute;
                            margin-left: -#{variables.$checkbox-padding};
                        }
                    }
                }
            }
        }
    }
}

.navbar .search-input {
    margin-left: 10%;
    width: 60%;

    @media (max-width: variables.$screen-lg-max) {
        margin-left: 5%;
    }

    @media (max-width: bs-var.$screen-sm-max) {
        margin-left: 0;
        width: 55%;
    }

    @media (max-width: bs-var.$screen-xs-max) {
        width: 100%;
    }
}
