@use 'styles/variables';

.dvb-kitax-betreuungsgutscheine {
    th {
        text-align: right;
    }

    th[scope="row"] {
        font-weight: variables.$normal-font-weight;
    }
}
