@use 'styles/modules/bs-var';
@use 'styles/modules/text-helper';
@use 'styles/variables';

.dvb-navigation {
    font-size: variables.$navbar-font-size;

    &.navbar {
        margin-bottom: 0;
    }

    .navbar-collapse,
    .navbar-form {
        border: none;
        box-shadow: none;
    }

    .dropdown-toggle {
        font-size: variables.$font-size-base * 1.5;
        color: variables.$color-guide-normal;

        &:focus,
        &:hover {
            color: variables.$color-guide-hover;
        }
    }

    &.navbar-fixed-top {
        @media (max-width: bs-var.$screen-xs-max) {
            position: relative;
        }
    }

    .btn-menu-toggle {
        border-radius: 0;
        background-color: variables.$color-main-normal;
        color: variables.$color-guide-normal;
        font-size: variables.$navbar-font-size;
        padding: variables.$navbar-padding-vertical;
        margin: 0;

        .fa {
            // not doing this will make the icon 1 pixel higher than the font-size which is weird
            // setting the line-height to zero somehow resolves the issue
            line-height: 0;
        }

        &.dropdown-toggle {
            width: 100%;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: variables.$color-main-normal;
            color: variables.$color-guide-middle;
        }
    }

    .navbar-nav {
        // not doing this will result the menu button being to far to the right
        margin: 0;

        @media (max-width: bs-var.$screen-xs-max) {
            // giving some margin add the bottom of the expanded navbar on xs-screens
            margin-bottom: variables.$small-abstand;
            padding: 0 (0.5 * variables.$grid-gutter-width);
        }
    }

    .dropdown {
        .btn {
            text-align: left;

            @media (max-width: bs-var.$screen-xs-max) {
                border-top: 1px solid variables.$color-guide;
            }
        }

        &.open {
            .btn-menu-toggle {
                background-color: variables.$color-main-hover;
            }

            @media (max-width: bs-var.$screen-xs-max) {
                .dropdown-menu {
                    overflow-y: auto;
                    font-size: variables.$font-size-h2;
                    margin-left: variables.$small-abstand;

                    a {
                        line-height: bs-var.$line-height-large;
                    }
                }
            }
        }
    }

    .nav-icon {
        font-size: 18px;
    }

    @media (min-width: bs-var.$screen-sm-min) {
        .username {
            font-size: variables.$font-size-base;
            line-height: variables.$font-size-icon-large;
            max-width: 110px;

            @include text-helper.text-overflow;
        }

        .user-icon {
            font-size: variables.$font-size-base;
            line-height: variables.$font-size-icon-large;
        }
    }

    .dropdown-support {
        a {
            display: inline-block;
        }

        a,
        .opening-hours {
            // makes link text selection a bit easier
            padding: 0;
            margin: 0 variables.$normal-abstand;
        }

        .opening-hours {
            white-space: pre-wrap;
        }
    }

    .navbar-brand svg {
        overflow: visible;
    }
}
