@use 'styles/modules/text-helper';

.dvb-corona-rueckerstattung-report {
    .generated-reports {
        .generated-report-button button {
            width: 100%;
            text-align: left;

            @include text-helper.text-overflow;
        }
    }
}
