@use 'styles/misc';
@use 'styles/variables' as v;

.panel {
    border: none;

    .panel-heading {
        background-color: v.$color-guide-normal;
        border: 0;
        color: v.$color-guide-bright-on-hover;
        font-size: v.$font-size-h2;
    }

    .panel-footer {
        padding: 0;
        background-color: v.$color-guide-bright;
        border-radius: 0;
        border-top: 1px solid v.$color-guide-bright-on-hover;

        .btn-link {
            @include misc.full-width-block;

            line-height: v.$floating-text-line-height;
            padding: v.$small-abstand;
            text-align: left;
        }
    }
}
