@use 'styles/mixins';
@use 'styles/variables';

.dvb-betreuungsverlauf {
    .dvb-belegung-interval-header {
        @include mixins.flex($grow: 1);
    }

    .dvb-belegung {
        padding-bottom: variables.$normal-abstand;
        border-bottom: variables.$border-default;

        .wochenplan .tag .belegungseinheiten .belegungseinheit .feld.selected.border-green {
            background-color: variables.$color-main-bright;
            color: variables.$color-main-normal;
        }

        &:last-child {
            padding-bottom: 0;
            border: none;
        }
    }

    .interval-footer {
        display: flex;

        &-column {
            width: 0;

            &-left {
                @include mixins.flex($grow: variables.$status-und-name-flex-grow + variables.$icons-flex-grow);
            }

            &-right {
                @include mixins.flex($grow: variables.$total-flex-grow - variables.$status-und-name-flex-grow - variables.$icons-flex-grow);
            }
        }
    }
}
