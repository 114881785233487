@use 'styles/variables';

.dvb-zuweisen-header {
    .kind-details {
        h2,
        ul {
            display: inline;
        }

        ul {
            margin: variables.$small-abstand;
        }
    }
}
