@use 'styles/modules/text-helper';
@use 'styles/mixins';
@use 'styles/variables' as v;

// Nav
.navbar-fixed-top {
    border: none;

    .kitadmin-content {
        padding: 0 (2 * v.$font-size-base);
    }
}

.navbar-collapse.collapsing {
    // do not show scrollbars while the navbar is collapsing in or out.
    overflow-y: hidden;
}

.navbar-brand {
    img {
        width: v.$font-size-base * 6;
    }
}

// Required for Angular UI Bootstrap
.nav,
.pagination,
.panel-title a {
    cursor: pointer;
}

// Dropdown
.dropdown-menu {
    box-shadow: none;
    border: none;
    padding: v.$small-abstand 0;
    margin: 0;
    border-radius: 0;
    cursor: default;

    .divider {
        margin: v.$small-abstand v.$normal-abstand;
    }

    li {
        a,
        button {
            padding: 0 v.$normal-abstand;
            font-weight: 600;
            line-height: v.$floating-text-line-height;
        }
    }
}

// Typeahead
typeahead-container {
    width: 100%;
}

dv-timeline-group-add-resource {
    typeahead-container {
        width: unset;
    }
}

// Badges
.badge {
    @include mixins.colors-selected;

    border-radius: 2px;

    &.warning {
        background-color: v.$color-contrast-normal;
    }

    &.search {
        @include mixins.label-colors-default;

        $margin: (v.$font-size-base * 3) * 0.5;

        right: 0;
        position: absolute;
        margin: v.$font-size-base - 3px;
        max-width: calc(40% - (2 * $margin));
        line-height: v.$font-size-base;
        background-color: v.$color-guide-bright-on-hover;

        @include text-helper.text-overflow;
    }
}

.superscript {
    font-size: v.$font-size-base;
    vertical-align: top;
}

// Tooltips
.tooltip {
    font-size: v.$font-size-small;
    line-height: v.$font-size-base;
}

.tooltip-inner {
    font-weight: v.$bold-font-weight;
    text-align: left;
    max-width: inherit;
    white-space: pre;
}

.bs-tooltip-top .tooltip-arrow {
    margin-left: 3px !important;
}

// Progress bar
.progress {
    height: 10px;
}

// Helper classes
@mixin inline-icons() {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .list-inline !optional; // kommt aus bootstrap-sass/.../_type.scss

    display: flex;
    flex-direction: row;

    li {
        @include mixins.flex($grow: 0, $shrink: 1, $basis: auto);
    }
}

.inline-icons {
    @include inline-icons;
}

.centered-spinner {
    display: block;
    text-align: center;
}

.inline-block {
    display: inline-block;
}

@mixin full-width-block() {
    display: block;
    width: 100%;
}

.full-width-block {
    @include full-width-block;
}

.list-unstyled li {
    margin-bottom: v.$small-abstand;
}

@mixin list-inline-row() {
    margin-bottom: 0;
}

.list-inline-row {
    @include list-inline-row;
}

.main-actions {
    margin-top: v.$big-abstand;
}

.floating-text {
    line-height: v.$floating-text-line-height;
}

.floating-text-list {
    li {
        line-height: v.$floating-text-line-height;
    }
}

.flex-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: v.$normal-abstand;
}

.first-container {
    @include mixins.border-top;
}

.normal-abstand-container {
    display: flex;
    flex-direction: column;
    gap: v.$normal-abstand;
}

.table-header {
    padding: v.$smaller-abstand v.$small-abstand;
    background-color: v.$color-main-normal;
    color: v.$color-main-bright-on-hover;
}

// text-overflow: ellipsis
// This comment exists so this utility class can be found more easily
.truncate {
    @include text-helper.text-overflow;
}

.nl2br {
    white-space: pre-line;
}

.popover-list {
    margin: v.$small-abstand;
    padding-left: v.$small-abstand;

    li {
        margin-bottom: v.$smaller-abstand;
    }
}

.cancel-button {
    line-height: v.$tall-line-height;
}

.placeholder-color {
    color: v.$color-main-middle;
}

.flex {
    display: flex;
    align-items: center;
}
