@use 'styles/variables';

.dvb-rechnung-heading {
    font-weight: variables.$bold-font-weight;
    margin-left: -#{variables.$small-abstand};
    margin-right: -#{variables.$small-abstand};
    line-height: variables.$floating-text-line-height;

    > div:first-child {
        padding-left: variables.$small-abstand;
    }

    > div:last-child {
        padding-right: variables.$small-abstand;
    }

    .double-line {
        line-height: variables.$line-height-normal;
        font-weight: variables.$normal-font-weight;

        .dvb-rechnung-heading-attribute p {
            line-height: variables.$line-height-normal;
        }
    }

    .double-line-height {
        line-height: variables.$line-height-normal * 2;
    }
}
