@use 'styles/mixins';
@use 'styles/variables' as v;

.history-columns {
    display: flex;
}

.history-column-left {
    @include mixins.flex($grow: v.$status-und-name-flex-grow + v.$icons-flex-grow);

    width: 0;
    display: flex;
    flex-direction: column;
}

.history-column-middle {
    @include mixins.flex($grow: v.$total-flex-grow - v.$status-und-name-flex-grow - v.$icons-flex-grow - 1);

    width: 0;
}

.history-column-right {
    @include mixins.flex($grow: 1);

    width: 0;
}
