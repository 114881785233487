@use 'styles/variables';

.dvb-rechnung-uebersicht {
    .kind-list-element {
        &::after {
            content: ', ';
        }

        &:last-child::after {
            content: '';
        }
    }

    .status-icon {
        width: variables.$list-icon-width;
        display: inline-block;
    }
}
