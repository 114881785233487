@use 'styles/variables';

.dvb-kita-uebersicht {
    .stats-header {
        white-space: nowrap;
    }

    .stats-number {
        font-weight: variables.$bold-font-weight;
        margin-top: variables.$smaller-abstand;
    }

    .stats-number-big {
        font-size: 25px;
    }

    .chart-bewilligte-plaetze {
        color: variables.$color-contrast-hover;
    }

    .chart-maximale-plaetze {
        color: variables.$color-contrast-normal;
    }

    .chart-plaetze {
        color: variables.$color-guide-hover;
    }

    .chart-belegte-plaetze {
        color: variables.$color-main-normal;
    }

    .chart-forecast-stunden {
        color: variables.$color-contrast-alt-normal;
    }

    .graph-placeholder {
        height: 370px;
    }
}
