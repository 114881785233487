@use 'styles/modules/text-helper';
@use 'styles/mixins';
@use 'styles/variables';

.dvb-kita-rechnungslauf-finish-entry {
    padding: 0 variables.$small-abstand;

    .entry-heading:hover {
        cursor: pointer;
    }

    &:hover .row,
    &.selected-entry .row {
        @include mixins.colors-default;
    }

    .collapsible-panel-container {
        display: block;
    }

    input[type='checkbox'] {
        margin-top: 0;
        height: variables.$font-size-base * variables.$floating-text-line-height;
    }

    .checkbox label {
        line-height: variables.$floating-text-line-height;
    }

    .dvb-tasks-badge,
    .dvb-versandart-badge {
        display: inline-block;

        // in case the badges appear multi line, we want some margin between them
        margin: 3px 0;
    }

    .recipient {
        // we want ellipsis while keeping space for a vorauszahlungs icon to the right
        max-width: calc(100% - 70px);

        strong {
            display: inline-block;
            width: 100%;
            vertical-align: top;

            @include text-helper.text-overflow;
        }
    }

    .vorauszahlung {
        line-height: variables.$line-height-normal;
    }
}
