@use '../variables';
@use './bs-var';

:root {
    --ka-color-main-normal: #{variables.$color-main-normal};
    --ka-color-main-bright: #{variables.$color-main-bright};
    --ka-color-guide-hover: #{variables.$color-guide-hover};
    --ka-color-guide-bright: #{variables.$color-guide-bright};
    --ka-color-contrast-hover: #{variables.$color-contrast-hover};
    --ka-color-contrast-normal: #{variables.$color-contrast-normal};
    --ka-color-contrast-alt-normal: #{variables.$color-contrast-alt-normal};
    --ka-text-color: #{bs-var.$text-color};
}
