@use 'styles/modules/bs-var';
@use 'styles/variables';

.dvb-date-switcher {
    .header-date {
        margin-top: variables.$font-size-icon;
        font-size: variables.$font-size-base;
        position: relative;
        white-space: nowrap;

        @media (max-width: bs-var.$screen-xs-max) {
            text-align: left;
        }

        li {
            min-width: variables.$font-size-large;
        }

        .date-navigation {
            text-align: center;
            position: relative;
            margin-bottom: variables.$normal-abstand;
        }

        .week-width {
            width: 225px;
        }

        .year-width {
            width: 60px;
        }

        .month-width {
            width: 113px;
        }

        .day-width {
            width: 130px;
        }
    }
}
