@use 'styles/mixins';
@use 'styles/variables';

.wochen-zeitplan {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    min-height: 150px;
    max-height: 300px;

    .tag {
        @include mixins.flex($grow: 1, $basis: 0);

        display: flex;
        flex-flow: column nowrap;
        margin-right: variables.$tageseinheit-cell-margin;

        .header {
            display: block;
            text-align: center;
            line-height: variables.$floating-text-line-height;
        }

        .zeitraum {
            flex-basis: 0;
            overflow: hidden;

            .feld {
                @include mixins.colors-default;

                position: relative;
                display: flex;
                flex-flow: column nowrap;
                height: calc(100% - #{variables.$tageseinheit-cell-margin});
                border-radius: variables.$input-border-radius;
                margin-bottom: variables.$tageseinheit-cell-margin;
                text-align: center;

                &.selected {
                    @include mixins.colors-selected;
                }

                .times {
                    // overlayed over sections with background colors
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    .bis {
                        // force bis zeit to bottom of zeitraum
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                    }
                }
            }

            &.nicht-verfuegbar {
                .feld {
                    @include mixins.nicht-verfuegbar-background;
                }
            }

            .sections {
                display: flex;
                flex-flow: column nowrap;
                height: 100%;

                .section {
                    flex-basis: 0;
                }
            }
        }
    }

    &.small {
        font-size: variables.$font-size-small;
        min-height: 88px;

        .tag .zeitraum:not(.zeitraum-zeit) .feld {
            line-height: variables.$font-size-tiny;
            font-size: variables.$font-size-tiny;

            &.border-red,
            &.border-green {
                line-height: variables.$small-tageseinheit-cell-height - variables.$tageseinheit-cell-margin * 2;
            }
        }

        .tag .zeitraum.zeitraum-zeit .feld {
            background-color: transparent !important;

            p {
                font-size: variables.$font-size-tiny;
                line-height: 0.5 * variables.$small-tageseinheit-cell-height;
            }
        }
    }
}
