@use 'styles/variables';

.dvb-rechnung-preview {
    display: flex;
    align-items: center;
    line-height: variables.$line-height-normal;

    .content {
        width: 100%;

        &.limited-width {
            max-width: 300px;
        }
    }
}
