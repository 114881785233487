@use 'styles/mixins';

.dvb-termine {
    @include mixins.year-switcher-heading;

    .bemerkung {
        white-space: pre-wrap;
    }

    .kinder-ort-link {
        vertical-align: middle;
    }
}
