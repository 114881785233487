$custom-icons-font-path: 'src/styles/custom-icons/fonts';

@font-face {
    font-family: icomoon;
    src: url('#{$custom-icons-font-path}/icomoon.ttf') format('truetype'),
        url('#{$custom-icons-font-path}/icomoon.woff') format('woff'),
        url('#{$custom-icons-font-path}/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.custom-icon {
    // use !important to prevent issues with browser extensions that change fonts

    // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
    font-family: icomoon !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.custom-icon-target::before {
    content: '\e900';
}

.custom-icon-policy::before {
    content: '\e901';
}

.custom-icon-limited::before {
    content: '\e902';
}

.custom-icon-subventioniert::before {
    content: '\e903';
}

.custom-icon-firmen::before {
    content: '\e904';
}

.custom-icon-privat::before {
    content: '\e905';
}

.custom-icon-warteliste::before {
    content: '\e906';
}

.custom-icon-angebot::before {
    content: '\e907';
}

.custom-icon-kindergarten-2::before {
    content: '\e908';
}

.custom-icon-kindergarten-1::before {
    content: '\e909';
}

.custom-icon-kindergarten::before {
    content: '\e90a';
}

.custom-icon-prov-zugewiesen::before {
    content: '\e90b';
}

.custom-icon-zugewiesen::before {
    content: '\e90c';
}

.custom-icon-mahnung-1::before {
    content: '\e90d';
}

.custom-icon-mahnung-2::before {
    content: '\e90e';
}

.custom-icon-mahnung-3::before {
    content: '\e90f';
}

.custom-icon-gesendet::before {
    content: '\e910';
}

.custom-icon-storniert::before {
    content: '\e911';
}

.custom-icon-sonstiges::before {
    content: '\e912';
}

.custom-icon-betreibung::before {
    content: '\e913';
}

.custom-icon-zahlungserinnerung::before {
    content: '\e914';
}

.custom-icon-plus::before {
    content: '\e915';
}

.custom-icon-hourglass::before {
    content: '\e916';
}

.custom-icon-palmtree::before {
    content: '\e917';
}

.custom-icon-clipboard-check::before {
    content: '\e918';
}

.custom-icon-chess-knight::before {
    content: '\e919';
}

.custom-icon-plus-thin::before {
    content: '\e91a';
}

.custom-icon-close-thin::before {
    content: '\e91b';
}
