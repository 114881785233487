@use 'styles/modules/bs-var';
@use 'styles/variables';

.dvb-error-messages {
    background-color: variables.$color-contrast-normal;
}

.dvb-success-messages {
    background-color: variables.$color-guide-normal;
}

.dvb-error-messages,
.dvb-success-messages {
    position: fixed;
    top: variables.$navbar-height;
    right: 0;
    left: 0;
    z-index: bs-var.$zindex-navbar-fixed - 1;
    color: variables.$color-contrast-bright-on-hover;
    font-weight: variables.$bold-font-weight;
    display: none;

    .container {
        margin-top: variables.$small-abstand;
        margin-bottom: variables.$small-abstand;
        display: flex;

        .alarm-icon {
            margin-right: variables.$small-abstand;
        }

        div {
            margin-right: auto;
        }

        .close-icon {
            margin-left: variables.$small-abstand;
            margin-right: variables.$small-abstand;
        }

        .fa {
            line-height: variables.$floating-text-line-height;
        }

        a, .btn-link {
            color: variables.$color-contrast-bright-on-hover;
        }
    }
}

.login {
    .dvb-error-messages,
    .dvb-success-messages {
        z-index: variables.$login-z-index;
    }
}

.dialog {
    .dvb-error-messages,
    .dvb-success-messages {
        top: 0;
        z-index: variables.$login-z-index;
    }
}
