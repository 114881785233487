@use 'styles/mixins';

.dvb-kind-leistungen {
    @include mixins.year-switcher-heading;

    .leistungen-pro-monate {
        > :first-child {
            @include mixins.border-top;
        }
    }
}
