@use 'styles/variables' as v;

.accordion-detail {
    .click2edit {
        background-color: transparent;
    }

    .form-control:not([disabled]),
    .click2edit:hover,
    .dvb-adresse .click-to-edit-adresse .dvb-adresse-display:hover {
        background-color: v.$background-color;
        color: v.$color-main;
    }
}
