@use 'styles/variables';

.dvb-kita-offene-posten {
    $horizontal-gutter-canceller: variables.$small-abstand - (0.5 * variables.$grid-gutter-width);

    .dvb-kontaktperson-offene-posten-liste {
        margin: -#{variables.$normal-abstand} -#{$horizontal-gutter-canceller} 0;

        .collapsible-panel-container {
            &.posten-table-header {
                border-bottom: none;
                padding: variables.$smaller-abstand $horizontal-gutter-canceller;
            }
        }

        .offener-posten {
            .collapsible-panel-container {
                border-bottom: variables.$border-white;
            }

            &:last-child .collapsible-panel-container {
                border-bottom: variables.$border-default;
            }
        }
    }

    .offene-posten {
        .accordion-detail {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    .has-datepicker .dvb-datepicker-text-field {
        margin-bottom: 0;
    }
}
