@use 'styles/mixins';
@use 'styles/variables';

.dvb-firmen-plaetze-verlauf {
    .plaetze-value {
        display: flex;

        .plaetze-value-title {
            @include mixins.flex($grow: variables.$status-und-name-flex-grow + variables.$icons-flex-grow);

            width: 0;
            display: flex;
            flex-direction: column;
        }

        .plaetze-value-plaetze-closed {
            @include mixins.flex($grow: variables.$total-flex-grow - variables.$status-und-name-flex-grow - variables.$icons-flex-grow - variables.$plaetze-flex-grow);

            width: 0;
        }

        .plaetze-value-plaetze {
            @include mixins.flex($grow: variables.$total-flex-grow - variables.$status-und-name-flex-grow - variables.$icons-flex-grow);

            width: 0;

            .input-anzahl-plaetze-value {
                width: 50%;
            }
        }

        .plaetze-value-kitas {
            @include mixins.flex($grow: variables.$plaetze-flex-grow);

            width: 0;
        }

        .plaetze-value-action {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            p {
                margin-top: variables.$normal-abstand + variables.$smaller-abstand;
            }
        }

        .negativ-abstand-top {
            margin-top: -#{0.5 * variables.$font-size-base + 2 * variables.$border-default-width};
        }
    }
}
