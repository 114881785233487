@use 'styles/variables';

.kindbetreuung {
    .wunsch-title {
        display: flex;

        .subtitle {
            margin: auto 0 variables.$normal-abstand auto;
            display: block;
        }
    }
}
