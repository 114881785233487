@use 'styles/modules/text-helper';
@use 'styles/modules/bs-var';
@use 'styles/mixins';
@use 'styles/misc';
@use 'styles/variables' as v;

.header {
    h1 {
        margin-top: 0;

        @include text-helper.text-overflow;

        float: left;
        min-width: v.$h1-min-width;

        @media (max-width: bs-var.$screen-sm-max) {
            margin-bottom: v.$normal-abstand;
        }
    }

    .subtitle {
        position: absolute;
        left: 0.5 * v.$grid-gutter-width;
        font-size: v.$font-size-base;
        top: v.$font-size-h1 + v.$small-abstand;
        font-weight: v.$bold-font-weight;
    }

    .layout-spacer {
        @include mixins.flex(1);

        display: block;
    }

    &-row {
        margin-top: v.$big-abstand;
        position: relative;
    }

    &-box {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        @media (max-width: bs-var.$screen-sm-max) {
            flex-wrap: wrap;
        }

        h1 {
            flex-grow: 0;
        }

        > * {
            flex-grow: 1;

            @media (max-width: bs-var.$screen-sm-max) {
                text-align: right;
            }
        }

        &-attachment {
            font-size: v.$font-size-base;
        }
    }

    &-icon {
        font-size: v.$font-size-icon-large;
        left: 0;
        line-height: v.$font-size-h1;
        position: absolute;
        vertical-align: text-top;
        margin-left: -#{v.$font-size-base};
    }

    &-icons {
        @include misc.inline-icons;

        font-size: v.$font-size-icon;
        margin: 0 v.$small-abstand;
        white-space: nowrap;
        cursor: default;
        align-items: flex-end;
        height: 2 * v.$font-size-base;

        li {
            flex-basis: v.$font-size-icon;
            height: v.$font-size-icon;

            .betreuungsfaktor-icon {
                width: v.$font-size-icon;
                height: v.$font-size-icon;
            }
        }
    }

    &-dropdown {
        height: v.$font-size-base;
        position: relative;
        white-space: nowrap;
        margin: 0 v.$small-abstand;
        flex-grow: 0;
        text-align: left;

        @media (max-width: bs-var.$screen-sm-max) {
            flex-grow: 1;
        }

        > a,
        > button {
            line-height: v.$font-size-h1;
            padding: 0 v.$smaller-abstand;
            height: v.$font-size-h1 + v.$small-abstand; // + $small-abstand to bridge the gap till the menu starts
            display: inline-block;
        }

        > button i {
            vertical-align: super;
        }

        &.open > a,
        &.open > button {
            background-color: v.$color-guide-bright;
        }

        .dropdown-menu {
            margin-top: v.$small-abstand * 2;
        }
    }

    &-breadcrumb {
        font-size: v.$font-size-large;
        margin-bottom: v.$big-abstand - v.$small-abstand;
        white-space: nowrap;
        text-align: right;
        margin-left: v.$normal-abstand;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        gap: v.$smaller-abstand;
        flex-wrap: wrap;

        @media (max-width: bs-var.$screen-sm-max) {
            margin-left: 0;
            width: 100%;
            justify-content: flex-start;

            li {
                display: block;
            }
        }

        > li {
            margin: 0;
            padding: 0;

            // stylelint-disable-next-line no-descending-specificity
            > a {
                font-weight: normal;
                color: v.$color-main-normal;
                padding: 0.5 * v.$grid-gutter-width;
                display: block;
                width: 100%;
                height: 100%;

                &:hover,
                &:focus {
                    color: v.$color-main-normal;
                    background-color: v.$color-guide-bright;
                }
            }
        }

        .active {
            background-color: v.$color-guide-bright;
            color: v.$color-main-normal;
        }
    }
}
