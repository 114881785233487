@use 'styles/modules/bs-var';
@use 'modules/color';
@use 'modules/constants';

// cannot use @use with bootstrap mixins - they require "global" variables instead of parameters :-(
@import 'styles/variables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/mixins';

html {
    position: relative;
    min-height: 100%;
}

body {
    color: color.$color-main-normal;
    padding-bottom: 2 * constants.$big-abstand;
    padding-top: constants.$navbar-height;

    @media (max-width: bs-var.$screen-xs-max) {
        padding-top: 0;
    }
}

// Creates additional larger width breakpoint for xtra large displays
@media (min-width: constants.$screen-xl-min) {
    @include make-grid(xl);

    .container {
        width: constants.$container-xl;
    }
}

// Adding visibility classes for xtra large displays (see node_modules/bootstrap/less/responsive-utilities.scss)
.visible-lg {
    @media (min-width: bs-var.$screen-lg-min) and (max-width: constants.$screen-lg-max) {
        @include responsive-visibility('.visible-lg');
    }

    &-lg-block {
        @media (min-width: bs-var.$screen-lg-min) and (max-width: constants.$screen-lg-max) {
            display: block !important;
        }
    }

    &-lg-inline {
        @media (min-width: bs-var.$screen-lg-min) and (max-width: constants.$screen-lg-max) {
            display: inline !important;
        }
    }

    &-lg-inline-block {
        @media (min-width: bs-var.$screen-lg-min) and (max-width: constants.$screen-lg-max) {
            display: inline-block !important;
        }
    }
}

.visible-xl {
    @media (min-width: constants.$screen-xl-min) {
        @include responsive-visibility('.visible-xl');
    }

    &-block {
        display: none !important;

        @media (min-width: constants.$screen-xl-min) {
            display: block !important;
        }
    }

    &-inline {
        display: none !important;

        @media (min-width: constants.$screen-xl-min) {
            display: inline !important;
        }
    }

    &-inline-block {
        display: none !important;

        @media (min-width: constants.$screen-xl-min) {
            display: inline-block !important;
        }
    }
}

// Fixes the jumping-content issues when switching from a view with scrollbars to a view without.
@media (min-width: bs-var.$screen-sm-min) {
    .kitadmin-content {
        margin-left: calc((100vw - #{constants.$container-sm}) * 0.5);
    }

    html {
        overflow-x: hidden;
    }
}

@media (min-width: bs-var.$screen-md-min) {
    .kitadmin-content {
        margin-left: calc((100vw - #{constants.$container-md}) * 0.5);
    }
}

@media (min-width: bs-var.$screen-lg-min) {
    .kitadmin-content {
        margin-left: calc((100vw - #{constants.$container-lg}) * 0.5);
    }
}

@media (min-width: constants.$screen-xl-min) {
    .kitadmin-content {
        margin-left: calc((100vw - #{constants.$container-xl}) * 0.5);
    }
}

