@use 'styles/modules/text-helper';
@use 'styles/mixins';
@use 'styles/variables';

.wochenplan {
    @mixin colors-frei() {
        color: variables.$color-main-bright-on-hover;
        background-color: variables.$color-main-normal;

        &:hover {
            background-color: variables.$color-main-hover;
        }
    }

    @mixin colors-selected-active() {
        color: variables.$color-guide-bright-on-hover;
        background-color: variables.$color-guide-normal;

        &:hover {
            background-color: variables.$color-guide-hover;
        }
    }

    @mixin colors-selected-border-green() {
        border: variables.$tageseinheit-cell-margin solid variables.$color-guide-normal;
        line-height: variables.$tageseinheit-cell-height - (variables.$tageseinheit-cell-margin * 2);
    }

    @mixin colors-selected-border-red() {
        border: variables.$tageseinheit-cell-margin solid variables.$color-contrast-normal;
        line-height: variables.$tageseinheit-cell-height - (variables.$tageseinheit-cell-margin * 2);
    }

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .tag {
        @include mixins.flex($grow: 1);

        width: 100%;
        margin-right: variables.$tageseinheit-cell-margin;

        &:last-child {
            margin-right: 0;
        }

        text-align: center;

        .header {
            display: block;
            text-align: center;
            line-height: variables.$floating-text-line-height;
        }

        .belegungseinheiten {
            display: flex;
            flex-flow: column nowrap;

            .belegungseinheit {
                @include mixins.flex($grow: 1);

                margin-bottom: variables.$tageseinheit-cell-margin;
                font-weight: variables.$bold-font-weight;

                .tooltip.in {
                    opacity: 1;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .interactive {
                    // Damit bei klickbaren Feldern der Zeiger ändert
                    cursor: pointer;

                    &:hover {
                        background-color: variables.$color-main-middle;
                    }
                }

                .feld {
                    @include mixins.colors-default;

                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    align-items: center;
                    column-gap: 3px;
                    min-height: variables.$tageseinheit-cell-height;

                    .zeitraum-feld-icon {
                        // Positioniere das maxPlaetze Icon vertikal zentriert
                        display: inline-block;
                        line-height: variables.$font-size-base;
                        vertical-align: middle;

                        .dv-pensum-icon {
                            vertical-align: middle;
                        }
                    }
                }

                .error {
                    input {
                        background-color: variables.$color-contrast-normal;
                        box-shadow: none;
                        border: transparent;

                        &:hover {
                            background-color: variables.$color-contrast-hover;
                        }

                        &:focus {
                            background-color: variables.$background-color;
                            box-shadow: none;
                        }
                    }

                    background-color: lightcoral; // TODO use variable for color
                }

                .no-value,
                .no-feld {
                    // FIXME inherit properties of no-feld, aber &:extend(.no-feld all) funktioniert nicht
                    // Wichtig: muss vor .maximumUeberschritten definiert werden
                    @include mixins.strike-through;

                    // stylelint-disable-next-line no-descending-specificity
                    input {
                        background: inherit;
                        background-color: variables.$color-main-bright;
                        border: transparent;
                        box-shadow: none;

                        &:hover {
                            background: inherit;
                            background-color: variables.$color-main-middle;
                        }

                        &:focus {
                            background-color: variables.$background-color;
                            box-shadow: none;
                            border: variables.$border-default;
                            border-radius: variables.$input-border-radius;
                            outline: 0;
                            padding: 0;
                        }
                    }
                }

                .maximum-ueberschritten {
                    @include mixins.colors-error;
                }

                .freie-plaetze {
                    @include colors-frei;
                }

                .circle {
                    @include mixins.colors-selected;
                }

                .contrast-alt {
                    @include mixins.colors-contrast-alt;
                }

                .selectededitable,
                .selected {
                    &.border-green {
                        @include colors-selected-border-green;
                    }

                    &.border-red {
                        @include colors-selected-border-red;
                    }
                }

                .selected {
                    &:not(.border-green, .border-red) {
                        @include mixins.colors-selected;
                    }

                    &.error {
                        background-color: indianred;
                    }

                    .circle {
                        @include mixins.colors-default;
                    }
                }

                .selectededitable {
                    @include mixins.colors-selected-editable;
                }

                .active {
                    @include colors-selected-active;
                }

                .active.error {
                    background-color: darkred;
                }

                .no-feld.error input {
                    @include mixins.colors-error;

                    &:focus {
                        background-color: variables.$background-color;
                    }
                }

                .zeitraum-feld-input {
                    @include colors-selected-active;

                    display: block;
                    line-height: variables.$tageseinheit-cell-height;
                    min-height: variables.$tageseinheit-cell-height;
                    border-radius: variables.$input-border-radius;
                    width: 100%;
                    text-align: center;
                    border: transparent;
                    padding: 0;

                    &:focus {
                        background-color: variables.$background-color;
                        box-shadow: none;
                        color: variables.$color-main-normal;
                        border: variables.$border-default;
                        border-radius: variables.$input-border-radius;
                        outline: 0;
                        padding: 0;

                        // Die 2 Pixel, die durch den Border hinzukommen ziehen wir hier wieder ab, damit die Hoehe
                        // konstant bleibt
                        line-height: variables.$tageseinheit-cell-height - 2px;
                        min-height: variables.$tageseinheit-cell-height - 2px;
                    }
                }

                .zeitraum-name {
                    display: none;
                    height: 0;
                    width: 100%;

                    div {
                        position: relative;
                        height: variables.$font-size-tiny + 2px;
                        line-height: variables.$font-size-tiny;
                        text-align: left;

                        span {
                            font-size: variables.$font-size-tiny;
                            position: absolute;
                            padding: 2px;
                            width: 100%;

                            @include text-helper.text-overflow;
                        }
                    }
                }

                &:hover {
                    .zeitraum-name {
                        display: block;
                    }
                }

                &.zeitraum-zeit,
                &.zeitraum-name {
                    font-weight: variables.$normal-font-weight;
                    font-size: variables.$font-size-small;

                    .feld {
                        background-color: transparent;
                        flex-direction: column;

                        p {
                            line-height: 0.5 * variables.$tageseinheit-cell-height;
                        }
                    }
                }

                &.zeitraum-zeit .feld {
                    justify-content: flex-start;
                }
            }
        }
    }

    &.small {
        font-size: variables.$font-size-small;

        .tag .belegungseinheiten .belegungseinheit:not(.zeitraum-zeit) .feld {
            min-height: variables.$small-tageseinheit-cell-height;

            &.border-red,
            &.border-green {
                line-height: variables.$small-tageseinheit-cell-height - (variables.$tageseinheit-cell-margin * 2);
            }
        }

        .tag .belegungseinheiten .belegungseinheit.zeitraum-zeit .feld {
            min-height: variables.$small-tageseinheit-cell-height;
            background-color: transparent !important;

            p {
                font-size: variables.$font-size-tiny;
                line-height: 0.5 * variables.$small-tageseinheit-cell-height;
            }
        }
    }
}
