@use 'styles/mixins';
@use 'styles/modules/bs-var';
@use 'styles/variables';

.dvb-angestellte-kind-betreuung-erfassung {
    // Stundenerfassung will potentially be used on mobile devices
    // --> Increased calendar size and give the day buttons a size that can comfortably be tapped
    .dvb-calendar.calendar-wide table {
        width: 100%;

        td.uib-day {
            button {
                line-height: 3;

                &.active {
                    color: variables.$color-main-normal !important;
                    background-color: variables.$color-guide-middle !important;
                }

                &:hover:not(&.active) {
                    background-color: variables.$color-guide-hover;
                }
            }

            // days with no belegung
            // stylelint-disable-next-line no-descending-specificity
            &.empty button {
                color: variables.$color-main-normal;
                background-color: variables.$color-main-bright;
            }

            // days outside of the relevant date range
            &.disabled button {
                @include mixins.strike-through;

                // stylelint-disable-next-line no-descending-specificity
                &:hover {
                    background-color: variables.$color-main-bright !important;
                }
            }

            // days with belegung
            // stylelint-disable-next-line no-descending-specificity
            &.belegt button {
                color: variables.$color-main-bright-on-hover;
                background-color: variables.$color-main-normal;

                // current day
                &:not(.active) .text-info {
                    color: variables.$color-main-bright-on-hover;
                }
            }

            // stylelint-disable-next-line no-descending-specificity
            &.erfasst button {
                color: variables.$color-main-bright-on-hover;
                background-color: variables.$color-guide-normal;

                // stylelint-disable-next-line no-descending-specificity
                .text-info {
                    color: variables.$color-main-bright-on-hover;
                }

                &.active .text-info {
                    color: variables.$color-main-normal;
                }
            }
        }
    }

    .actions {
        line-height: 3;
    }

    .korrektur-zeiten-list {
        margin-top: variables.$smaller-abstand;
        padding-left: 0;
        font-size: variables.$font-size-small;
        list-style: none;

        li {
            margin-bottom: variables.$smaller-abstand;
        }
    }

    .korrektur-input {
        // based on $form-control + label
        min-height: bs-var.$input-height-base + variables.$normal-abstand + bs-var.$padding-large-vertical + variables.$font-size-base;
    }

    .korrektur-original {
        margin-top: -#{variables.$normal-abstand};
        margin-bottom: 0;
        font-size: variables.$font-size-small;
        padding-top: bs-var.$padding-small-vertical;
        padding-left: bs-var.$padding-small-horizontal;
    }
}
