@use 'font-awesome/scss/variables' as fa;
@forward 'font-awesome/scss/variables';

// cope of font-awesome mixins, to make it work with esbuild
@mixin fa-icon() {
    display: inline-block;
    font: normal normal normal #{fa.$fa-font-size-base}/#{fa.$fa-line-height-base} FontAwesome; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
