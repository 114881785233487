@use 'styles/variables';

.dvb-global-search {
    .full {
        width: 100%;
        padding-left: 0;
    }

    .search-filters {
        li {
            margin-bottom: variables.$small-abstand;
        }
    }

    .search-results {
        list-style-type: none;
        padding-left: 0;

        li {
            a {
                border-top: 1px solid variables.$color-main-middle;
                display: inline-block;
                width: 100%;
                padding: variables.$normal-abstand 0;

                dv-search-result-icon {
                    padding: 0 variables.$small-abstand;
                    width: variables.$font-size-icon-large * 2;
                }

                .badge {
                    right: 0;
                    position: absolute;
                    margin: 0 variables.$small-abstand * 2;
                    line-height: variables.$font-size-base;
                }
            }
        }
    }
}
