@use 'styles/variables';

.dvb-version {
    .small {
        font-size: variables.$font-size-small;
    }

    .language {
        .btn-link {
            font-weight: variables.$normal-font-weight;
        }

        .btn.active {
            font-weight: variables.$bold-font-weight;
            cursor: default;
        }

        .wip {
            position: absolute;
            display: inline-block;
            margin-top: -4px;
            margin-left: 2px;
            font-size: variables.$font-size-tiny;
        }
    }
}
