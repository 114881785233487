@use 'styles/variables';

.dvb-rechnung-detail {
    .no-rechnung-header {
        & > div:first-child .position-kind {
            border-top: none;
        }

        &.detail.detail-positions {
            margin-top: 0;
        }
    }

    .detail {
        background-color: variables.$color-main-bright;
        padding: variables.$normal-abstand variables.$small-abstand;
        border-bottom: variables.$border-default;
        overflow: hidden;

        &:last-child {
            border-bottom: none;
        }

        &.detail-positions {
            margin-top: -#{variables.$small-abstand};
            padding-top: 0;
            border-bottom: variables.$border-white;
        }

        &.detail-no-border {
            border: none;
        }
    }

    &.flat-layout .detail {
        background: none;
        padding: 0;
        border: none;

        &.rechnungs-total {
            margin-top: variables.$normal-abstand;
            padding: variables.$normal-abstand 0;
            border: variables.$border-default;
            border-width: variables.$border-default-width 0;
        }

        &.amount-pending {
            margin-top: variables.$normal-abstand;
        }
    }
}
