@use 'styles/mixins';
@use 'styles/variables';

.belegungsplan-filter {
    .verfuegbar-ab {
        width: 100px;
    }

    .alter {
        width: 38px;
    }

    .anzahl-plaetze {
        min-width: 3 * variables.$font-size-base;
        display: inline-block;
        text-align: right;

        .positiv-anzahl {
            &::before {
                content: '+';
            }
        }

        .fa {
            font-size: variables.$font-size-small - 1px; /* Ohne den Abzug dieses Pixels springt es im IE */
        }
    }

    .negative-anzahl .btn {
        @include mixins.colors-warning;

        &.active {
            @include mixins.colors-error;
        }
    }
}
