@use 'styles/variables';

.kinderort-anwesenheit-settings {
    .row {
        .day-label {
            line-height: variables.$line-height-base;
            padding: variables.$small-abstand 0;
        }
    }
}
