@use 'styles/variables';

.dvb-external-anmeldung-privacy-confirmation {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    padding: variables.$small-abstand variables.$normal-abstand;
    width: 100%;
    background-color: variables.$color-guide-bright;
    text-align: center;

    .list-inline {
        display: inline;
        margin-left: 0;
    }

    .info {
        vertical-align: middle;
        line-height: variables.$tall-line-height;
    }
}
