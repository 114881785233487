@use 'styles/variables';

.dvb-pensum {
    display: flex;

    input {
        width: 75px;
        margin-bottom: 0;
    }

    .form-linker {
        margin: 0 variables.$small-abstand;
    }
}
