@use 'styles/variables' as v;

.layout-row,
.layout-column {
    display: flex;
    justify-content: space-between;
}

.layout-row {
    flex-direction: row;

    .radio + .radio {
        // Bootstrap margin override
        margin-top: 10px;
    }
}

.flex-start {
    justify-content: flex-start;
}

.layout-column {
    flex-direction: column;
}

.items-flex-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.self-baseline {
    align-self: baseline;
}

.accordion-header-layout {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: v.$smaller-abstand v.$normal-abstand;
}
